import { useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Body from '../components/Body';
import InputField from '../components/InputField';
import { useApi } from '../contexts/ApiProvider';
import { useFlash } from '../contexts/FlashProvider';
import { useUser } from '../contexts/UserProvider';
import FormButtonBlock from '../components/FormButtonBlock';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';



export default function EditUserPage() {
    const [formErrors, setFormErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const emailField = useRef();


    const api = useApi();
    const flash = useFlash();

    const user = useUser();



    const onSubmit = async (event) => {
        event.preventDefault();
        setFormErrors();
        if (!emailField.current.value) {
            setFormErrors({ email: "Must include an email" });
        }
        else {
            const response = await api.post('/tokens/new-confirmation-token', {
                email: emailField.current.value,
            });
            if (response.ok) {
                flash('Check your email to confirm you account. Your confirmation code expires shortly.', 'success', 30);

            }
            else {
                if (response && 'body' in response && 'errors' in response) {

                    setFormErrors(response.body.errors.json);
                    // flash(response.body.errors.json.message, 'danger')
                }
                else {
                    flash('There was an error with your request', 'danger')
                }
            }
        }
    };




    return (
        <Body sidebar classStr={{ height: '100vh' }}>

            <Container fluid>


                <Row>
                    <Col xs={12}>
                        <h2 className="pricingSubHeader">Receive another confirmation</h2>
                    </Col>
                </Row>
                <Container className="mt-5">
                    <Row className="d-flex justify-content-center">
                        <Col xs={12} md={4} lg={6}>
                            {

                                <>
                                    <h3>Enter your email</h3>
                                    <Form onSubmit={onSubmit}>
                                        <InputField
                                            name="email" label="Email" type="email"
                                            error={formErrors.email} fieldRef={emailField} />


                                        <FormButtonBlock text={'Resend account confirmation'} />
                                    </Form>
                                </>

                            }
                        </Col>

                    </Row>
                </Container>
            </Container>

        </Body>
    );
}