import React from 'react'
import Body from '../components/Body'
import { Helmet } from 'react-helmet';
import Stack from 'react-bootstrap/Stack'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import CircleBadge from '../components/CircleBadge'
import ContributionOutcomeCardGroup from '../components/ContributionOutcomeCardGroup';
import YoutubeVideo from '../components/YoutubeVideo'
import youtubeUrls from '../utils/youtubeUrls';
import { GoArrowDown } from "react-icons/go";


function HowItWorksPage() {

    const LIST_CLASSES = "fs-5 text-secondary"
    return (
        <>
            <Helmet>
                <title>CrowdPost - How it Works</title>
                <meta property="og:title" content={`Let your voice reach the real town square`} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:image" content={`${process.env.REACT_APP_BASE_API_URL}${campaign.image_url}`} /> */}
                {/* <meta property="og:url" content={`${process.env.REACT_APP_BASE_API_URL}/campaigns/${campaign.id} `} /> */}
                <meta property="og:description" content="Contribute to this campaign for this image to appear on a billboard." />

            </Helmet>
            <Body classStr={{ minHeight: '100vh' }} footer>



                <Row >


                    <Col xs={12}>
                        <h1 id="howitworks" className="page-header text-center fw-bolder mb-4"> How it works</h1>
                    </Col>


                    <Col className="mt-5" xs={12} lg={6}>
                        <YoutubeVideo
                            url={youtubeUrls['CREATING_CAMPAIGNS']}
                            title={"Creating campagns"}
                        />
                        <h1 className="text-secondary  text-center">
                            Creating Campaigns
                        </h1>
                    </Col>
                    <Col className="mt-5" xs={12} lg={6}>
                        <YoutubeVideo
                            url={youtubeUrls['CONTRIBUTING_CAMPAIGNS']}
                            title={"Contributing to campagns"}
                        />
                        <h1 className="text-secondary text-center">
                            Contributing to Campaigns
                        </h1>
                    </Col>
                </Row>
                <hr className="my-5" />
                <Row>
                    <Col xs={12}>
                        <Stack gap={3}>
                            <Col sm={12} >
                                <Stack>
                                    <div style={{ display: 'inline-flex' }}>
                                        <h1 className="mb-5 fw-bolder">
                                            How It Works
                                        </h1>
                                    </div>
                                    <div style={{ display: 'inline-flex' }}>
                                        <CircleBadge number={1} />
                                        <h2 className="mx-3 fw-bolder">
                                            Post a campaign
                                        </h2>
                                    </div>
                                    <ul className={LIST_CLASSES}>
                                        <li>
                                            Select between billboard and mobile truck media
                                        </li>
                                        <li>
                                            Set a location for your posting
                                        </li>
                                        <li>
                                            Upload or create your own media
                                        </li>
                                        <li>
                                            Set the campaign fundraising period. At the end, your campaign is submitted to our media partners.
                                        </li>
                                        <li>
                                            Title and describe your campaign
                                        </li>
                                        <li>
                                            Post & Share!
                                        </li>
                                    </ul>
                                </Stack>
                            </Col>
                            <Col sm={12}>
                                <Stack>
                                    <div style={{ display: 'inline-flex' }}>
                                        <CircleBadge number={2} />
                                        <h2 className="mx-3 fw-bolder">
                                            Share & Raise Money for your Campaign
                                        </h2>
                                    </div>
                                    <ul className={LIST_CLASSES}>
                                        <li>
                                            Contributions are <strong>not processed up front</strong>, but at the end of the fundraising period if enough money is raised to purchase media.
                                        </li>
                                        <li>
                                            Anyone can contribute to campaigns, <strong>no account required</strong>
                                        </li>


                                        <li >
                                            Share your campaign to raise contributions
                                        </li>

                                    </ul>
                                </Stack>
                            </Col>
                            <Col sm={12}>
                                <Stack>
                                    <div style={{ display: 'inline-flex' }}>
                                        <CircleBadge number={3} />
                                        <h2 className="mx-3 fw-bolder">
                                            CrowdPost submits the campaign to our media partners at the end of its fundraising period
                                        </h2>
                                    </div>
                                    <ul className={LIST_CLASSES}>
                                        <li>
                                            <h5>The campaign stops accepting contributions</h5>
                                        </li>

                                        <li>
                                            <h5>Our media partners determine if the campaign will be <strong className="text-white">underfunded</strong>, <strong className="text-success">accepted</strong> or <strong className="text-danger">denied</strong>. </h5>
                                        </li>


                                    </ul>
                                </Stack>
                            </Col>
                            <Col xs={12} className="d-flex justify-content-center">

                                <GoArrowDown size={50} />
                            </Col>
                            <Col xs={12} className="fw-bolder fs-1 d-flex justify-content-center">

                                Decision received from our media partners
                            </Col>
                            <Col xs={12} className="d-flex justify-content-center">

                                <GoArrowDown size={50} />
                            </Col>

                            <Col xs={12}>
                                <ContributionOutcomeCardGroup />
                            </Col>
                        </Stack>
                    </Col>


                </Row>





            </Body >


        </>
    )
}

export default HowItWorksPage