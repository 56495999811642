import React, { useEffect } from 'react'
import Popover from 'react-bootstrap/Popover';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { MdOutlineTextRotationAngleup } from "react-icons/md";
import { RiFontColor } from "react-icons/ri";
import { BiSolidColorFill } from "react-icons/bi";
import { FaBold } from "react-icons/fa";
import { FaItalic } from "react-icons/fa";
import { FaUnderline } from "react-icons/fa";
import { FaAlignLeft } from "react-icons/fa6";
import { FaAlignJustify } from "react-icons/fa6";
import { FaAlignRight } from "react-icons/fa6";
import { MdAlignVerticalBottom } from "react-icons/md";
import { MdAlignVerticalCenter } from "react-icons/md";
import { MdAlignVerticalTop } from "react-icons/md";
import { useImageBuilder } from '../contexts/ImageBuilderProvider';
import CustomRangeField from './CustomRangeField';
import Stack from 'react-bootstrap/esm/Stack';
import { GoSidebarExpand } from "react-icons/go";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import EditorDisplayButton from './EditorDisplayButton';
import ClickHoverPopover from './ClickHoverPopover';
import { FaBorderAll } from "react-icons/fa";

function FontEditorDisplay({ handleToggleEditMessage, mediaName, handleImageHover, index, msgData, message_index, handleImageMessageListChange }) {

    const { handleUpdateBaseMessage, defaultColors } = useImageBuilder()

    useEffect(() => {
        message_index !== 'base_message' && handleImageHover(true)
        return () => {
            message_index !== 'base_message' && handleImageHover(false)
        }
    }, [index])

    const handleFontStyleChange = (style) => {

        msgData[style] === true ?
            handleImageMessageListChange(index, { [style]: false })
            :
            handleImageMessageListChange(index, { [style]: true })

    }



    const handleOutlineChange = (e, key_type, base = false) => {

        let data
        if (key_type === 'outline_blur') {
            console.log('blur func', e.target.value);


            data = { "outline_blur": e.target.value }

        }
        else if (key_type === 'outline_color') {
            data = { "outline_color": e }
        }
        else if (key_type === 'outline_width') {

            data = { "outline_width": e.target.value }

        }
        else if (key_type === 'change_to_blur') {
            data = { 'outline_width': 0, 'outline_type': 'shadow', 'outline_color': 'white' }

        }
        else if (key_type === 'change_to_none') {


            data = { 'outline_color': 'transparent', 'outline_type': 'none', 'outline_width': 0 }

        }
        else if (key_type === 'change_to_outline') {

            data = { 'outline_type': 'outline', 'outline_blur': 0, 'outline_color': 'white' }


        }
        if (base) {
            console.log(data)
            handleUpdateBaseMessage(mediaName, data)
        }
        else {
            handleImageMessageListChange(index, data)
        }



    }



    const handleFontBackgroundColorOpacityChange = (e, index) => {
        let opacity = e.target.value;
        let updated_resp_data;
        let currentColor;
        let color;


        if (parseInt(opacity) <= 99) {

            if (opacity <= 9) {
                opacity = `0${opacity}`
            }


            if (msgData['background_color'].length === 9) {
                currentColor = msgData['background_color'].slice(0, 7); // Remove the alpha value
                color = currentColor + opacity

            }
            else {
                color = msgData['background_color'] + `${opacity}`

            }

            handleImageMessageListChange(index, { "background_opacity": opacity, "background_color": color })

        }
        if (parseInt(opacity) === 100) {
            currentColor = msgData['background_color'].slice(0, 7); // Remove the alpha value
            color = currentColor;
            handleImageMessageListChange(index, { "background_opacity": opacity, "background_color": color })



        }
    }


    const BUTTON_BAR_ICON_SIZE = 24
    return (
        <>
            <Col xs={12} className="d-flex justify-content-start mt-3 mb-1">
                <Button size={'sm'} onClick={() => handleToggleEditMessage(false)} variant="primary">
                    <GoSidebarExpand size={18} />
                    <span className="mx-2"><strong>Finish edit</strong></span>
                </Button>

            </Col>
            <Col xs={12}>


                <div style={{ gap: '10px' }} xs={12} className="mt-2 d-flex flex-wrap align-items-center justify-content-sm-start justify-content-lg-center" role="toolbar" aria-label="Text formatting toolbar">
                    <ButtonGroup aria-label="Basic text formatting">
                        <Button onClick={(e) => handleFontStyleChange('bold')} variant="outline-secondary" aria-label="Bold">
                            <FaBold
                                size={BUTTON_BAR_ICON_SIZE}
                                style={{
                                    color: msgData['bold'] ? 'green' : 'white',

                                }}
                            />
                        </Button>
                        <Button onClick={(e) => handleFontStyleChange('italic')} variant="outline-secondary" aria-label="Italic">
                            <FaItalic
                                size={BUTTON_BAR_ICON_SIZE}
                                style={{

                                    color: msgData['italic'] ? 'green' : 'white',
                                }} />
                        </Button>
                        <Button onClick={(e) => handleFontStyleChange('underline')} variant="outline-secondary" aria-label="Underline">
                            <FaUnderline
                                size={BUTTON_BAR_ICON_SIZE}
                                style={{

                                    color: msgData['underline'] ? 'green' : 'white',
                                }} />

                        </Button>
                    </ButtonGroup>
                    <ButtonGroup aria-label="Basic text formatting">

                        <EditorDisplayButton body={"Align text left"} onClick={() => handleImageMessageListChange(index, { "text_align": 'left' })}>

                            <FaAlignLeft

                                size={BUTTON_BAR_ICON_SIZE}
                                className={msgData['text_align'] === 'left' ? 'text-success' : ''}
                            />

                        </EditorDisplayButton>

                        <EditorDisplayButton body={"Align text center"} onClick={(e) => handleImageMessageListChange(index, { "text_align": 'center' })}>

                            <FaAlignJustify

                                size={BUTTON_BAR_ICON_SIZE}
                                className={msgData['text_align'] === 'center' ? 'text-success' : ''} />

                        </EditorDisplayButton>




                        <EditorDisplayButton body={"Align text right"} onClick={() => handleImageMessageListChange(index, { "text_align": 'right' })}>

                            <FaAlignRight

                                size={BUTTON_BAR_ICON_SIZE}

                                className={msgData['text_align'] === 'right' ? 'text-success' : ''} />
                        </EditorDisplayButton>


                    </ButtonGroup>

                    <ButtonGroup aria-label="Basic text formatting">


                        {/* <Button onClick={() => handleImageMessageListChange(index, { 'vertical_align': 'end' })} variant="outline-secondary" aria-label="Bold"> */}
                        <EditorDisplayButton body={"Vertical bottom"} onClick={() => handleImageMessageListChange(index, { 'vertical_align': 'end' })}>
                            <MdAlignVerticalBottom
                                size={BUTTON_BAR_ICON_SIZE}

                                className={msgData['vertical_align'] === 'end' ? 'text-success' : ''} />
                        </EditorDisplayButton>
                        {/* </Button> */}

                        {/* <Button onTouchStart={() => handleImageMessageListChange(index, { 'vertical_align': 'center' })} onClick={() => handleImageMessageListChange(index, { 'vertical_align': 'center' })} variant="outline-secondary" aria-label="Italic"> */}
                        <EditorDisplayButton body={"Vertically centered"} onClick={() => handleImageMessageListChange(index, { 'vertical_align': 'center' })}>

                            <MdAlignVerticalCenter

                                size={BUTTON_BAR_ICON_SIZE}
                                className={msgData['vertical_align'] === 'center' ? 'text-success' : ''} />
                        </EditorDisplayButton>


                        {/* <Button onClick={() => handleImageMessageListChange(index, { 'vertical_align': 'baseline' })} variant="outline-secondary" aria-label="Underline"> */}
                        <EditorDisplayButton body={"Vertical top"} onClick={() => handleImageMessageListChange(index, { 'vertical_align': 'baseline' })}>
                            <MdAlignVerticalTop
                                size={BUTTON_BAR_ICON_SIZE}

                                className={msgData['vertical_align'] === 'baseline' ? 'text-success' : ''} />
                        </EditorDisplayButton>

                        {/* </Button> */}
                    </ButtonGroup>

                    <ClickHoverPopover
                        icon={<RiFontColor style={{ padding: '.2em', color: msgData['font_color'], borderRadius: '.25em' }} size={BUTTON_BAR_ICON_SIZE} />}
                        tooltipMsg="Font color"
                    >
                        <Popover id="popover-basic4">
                            <Row className="p-2 d-flex justify-content-between">
                                <Form.Label>Font Color</Form.Label>

                                <Col className="d-flex justify-content-center">
                                    {
                                        defaultColors.map((c, i) =>
                                            <div
                                                key={i}
                                                className="m-1"
                                                style={{
                                                    backgroundColor: c,
                                                    width: '24px',
                                                    height: '24px',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => handleImageMessageListChange(index, { 'font_color': c })}
                                            >
                                            </div>
                                        )
                                    }
                                </Col>
                                <Col xs={12}>
                                    <div className="mt-2">
                                        <p><strong>Custom</strong></p>
                                        <input style={{ cursor: 'pointer' }} type="color" value={msgData['font_color']} onChange={e => handleImageMessageListChange(index, { "font_color": e.target.value })} />
                                    </div>
                                </Col>
                            </Row>
                        </Popover>
                    </ClickHoverPopover>



                    <ClickHoverPopover
                        icon={<BiSolidColorFill style={{ padding: '.2em', backgroundColor: msgData['background_color'], borderRadius: '.25em' }} size={BUTTON_BAR_ICON_SIZE} />}
                        tooltipMsg="Background color"
                    >
                        <Popover id="popover-basic4">

                            <Form.Group className="p-2">
                                <Form.Label>Background Color</Form.Label>
                                <div>
                                    <Row className="d-flex justify-content-between">
                                        <Col className="d-flex justify-content-center">
                                            {
                                                defaultColors.map((c, i) =>
                                                    <div
                                                        key={i}
                                                        style={{
                                                            backgroundColor: c,
                                                            width: '24px',
                                                            height: '24px',
                                                            borderRadius: '5px',
                                                            cursor: 'pointer',
                                                        }}
                                                        className="m-1"
                                                        onClick={() => handleImageMessageListChange(index, { 'background_color': c })}
                                                    >
                                                    </div>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                    <div className="mt-2">
                                        <p><strong>Custom</strong></p>
                                        <input style={{ cursor: 'pointer' }} type="color" value={msgData['background_color']} onChange={(e) => handleImageMessageListChange(index, { 'background_color': e.target.value })} />
                                        <div className="mt-3">
                                            <Form.Group>
                                                <Form.Label><strong>Background Opacity</strong></Form.Label>
                                                {/* <div>{msgData['background_opacity']}</div> */}
                                                <CustomRangeField
                                                    value={msgData['background_opacity']}
                                                    min={0}
                                                    max={100}
                                                    step={1}
                                                    onChange={(e) => handleFontBackgroundColorOpacityChange(e, index)}
                                                ><strong>%</strong></CustomRangeField>
                                                {/*
        <Form.Range onChange={(e) => handleFontBackgroundColorOpacityChange(e, index)} value={msgData['background_opacity']} min={0} max={100} step={1} /> */}
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>

                                <Form.Switch
                                    active='true'
                                    className="mt-2"
                                    onClick={(e) => e.target.value === 'on' ?
                                        handleImageMessageListChange(index, { 'background_color': 'transparent' })
                                        :
                                        handleImageMessageListChange(index, { 'background_color': 'white' })
                                    }
                                    label="No background color" />
                            </Form.Group>
                        </Popover>



                    </ClickHoverPopover>

                    <ClickHoverPopover
                        icon={<RiFontColor style={{ color: msgData['outline_color'], padding: '.2em', fontColor: msgData['outline_color'], borderRadius: '.25em' }} size={BUTTON_BAR_ICON_SIZE} />}
                        tooltipMsg="Text outline color"
                    >
                        <Popover id="popover-basic8">
                            <Popover.Body >
                                <Form.Group>
                                    <Form.Label>
                                        <strong>Text outline</strong>
                                    </Form.Label>

                                    <Stack direction="horizontal" gap={2} className="mb-2">
                                        <Button
                                            variant="light"
                                            size="sm"
                                            onClick={(e) => handleOutlineChange(e, 'change_to_none')}
                                            className="rounded-pill"
                                            style={{
                                                backgroundColor: msgData['outline_type'] === 'none' ? 'blue' : 'lightgray',
                                                color: msgData['outline_type'] === 'none' ? 'white' : 'black',
                                            }}
                                        >
                                            No outline
                                        </Button>
                                        <Button
                                            variant="light"
                                            size="sm"
                                            onClick={(e) => handleOutlineChange(e, 'change_to_blur')}
                                            className="rounded-pill"
                                            style={{
                                                backgroundColor: msgData['outline_type'] === 'shadow' ? 'blue' : 'lightgray',
                                                color: msgData['outline_type'] === 'shadow' ? 'white' : 'black',
                                            }}
                                        >
                                            Blur
                                        </Button>
                                        <Button
                                            variant="light"
                                            size="sm"
                                            onClick={(e) => handleOutlineChange(e, 'change_to_outline')}
                                            className="rounded-pill"
                                            style={{
                                                backgroundColor: msgData['outline_type'] === 'outline' ? 'blue' : 'lightgray',
                                                color: msgData['outline_type'] === 'outline' ? 'white' : 'black',
                                            }}
                                        >
                                            Outline
                                        </Button>
                                    </Stack>

                                </Form.Group>


                                <Form.Group>
                                    <Form.Label><strong>Outline Width</strong></Form.Label>
                                    {
                                        msgData['outline_type'] === 'outline' ?
                                            <CustomRangeField
                                                value={msgData['outline_width']}
                                                min={0}
                                                max={.1}
                                                step={.005}
                                                onChange={(e) => handleOutlineChange(e, 'outline_width')}
                                            ><strong> px</strong></CustomRangeField>
                                            :
                                            msgData['outline_type'] === 'shadow' ?
                                                <CustomRangeField
                                                    value={msgData['outline_blur']}
                                                    min={0}
                                                    max={1}
                                                    step={.005}
                                                    onChange={(e) => handleOutlineChange(e, "outline_blur")}
                                                ><strong> px</strong></CustomRangeField>
                                                :
                                                <CustomRangeField
                                                    value={0}
                                                    min={0}
                                                    max={1}
                                                    step={.005}
                                                    disabled
                                                ><strong> px</strong></CustomRangeField>
                                    }
                                </Form.Group>
                                {
                                    msgData['outline_type'] !== 'none' &&
                                    <Form.Group>
                                        <Form.Label><strong>Outline Color</strong></Form.Label>
                                        <Row className="d-flex justify-content-between">
                                            <Col className="d-flex justify-content-center">
                                                {
                                                    defaultColors.map((c, i) =>
                                                        <div
                                                            key={i}
                                                            className="m-1"
                                                            style={{
                                                                backgroundColor: c,
                                                                width: '30px',
                                                                height: '30px',
                                                                borderRadius: '5px',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => handleOutlineChange(c, "outline_color")}
                                                        >
                                                        </div>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                        <div className="mt-2">
                                            <p><strong>Custom</strong></p>
                                            <input style={{ cursor: 'pointer' }} type="color" value={msgData['outline_color']} onChange={e => handleOutlineChange(e.target.value, "outline_color")} />
                                        </div>
                                    </Form.Group>
                                }

                            </Popover.Body>
                        </Popover>

                    </ClickHoverPopover>

                    <ClickHoverPopover
                        icon={<MdOutlineTextRotationAngleup style={{ padding: '.2em', fontColor: msgData['outline_color'], borderRadius: '.25em' }} size={BUTTON_BAR_ICON_SIZE} />}
                        tooltipMsg="Layer angle"
                    >
                        <Popover id="popover-basic3">
                            <Popover.Body >
                                <Form.Group >
                                    <Form.Label><strong>Layer Angle</strong></Form.Label>

                                    <CustomRangeField
                                        value={msgData['angle']}
                                        min={0}
                                        max={350}
                                        step={1}
                                        onChange={(e) => { e.preventDefault(); handleImageMessageListChange(index, { 'angle': e.target.value }) }}
                                    ><strong>°</strong></CustomRangeField>

                                </Form.Group>
                            </Popover.Body>
                        </Popover>



                    </ClickHoverPopover>



                    <ClickHoverPopover
                        icon={<FaBorderAll style={{ padding: '.2em', fontColor: msgData['rounded_border'], borderRadius: '.25em' }} size={BUTTON_BAR_ICON_SIZE} />}
                        tooltipMsg="Rounded border"
                    >
                        <Popover id="popover-basic3">
                            <Popover.Body >
                                <Form.Group >
                                    <Form.Label><strong>Rounded border</strong></Form.Label>
                                    <Form.Switch
                                        active='true'
                                        className="mt-2"
                                        onClick={(e) => e.target.checked === true ?
                                            handleImageMessageListChange(index, { 'rounded_border': true })
                                            :
                                            handleImageMessageListChange(index, { 'rounded_border': false })
                                        }
                                        label="Rounded border" />
                                    <hr />

                                </Form.Group>
                            </Popover.Body>
                        </Popover>



                    </ClickHoverPopover>




                    <DropdownButton
                        as={ButtonGroup}
                        variant="outline-secondary"
                        title={msgData['font_family']}
                        id="bg-nested-dropdown"
                        className="ml-2"
                    // 
                    >
                        {/* <Dropdown.Item style={{ fontStyle: 'Arial' }} eventKey="1">Normal text</Dropdown.Item>
                        <Dropdown.Item eventKey="2">Heading 1</Dropdown.Item> */}

                        {['Helvetica', 'Times New Roman', 'Arial', 'Courier New', 'Georgia', 'Trebuchet MS', 'Tahoma', 'Verdana'].map((v, i) =>
                            <Dropdown.Item
                                onClick={(e) => (e.preventDefault, handleImageMessageListChange(index, { 'font_family': v }))}
                                style={{
                                    fontFamily: v
                                }}
                                key={i}
                                value={v}
                                eventKey={i}

                            >
                                {v}
                            </Dropdown.Item>

                        )}
                    </DropdownButton>


                    <Form.Group >
                        <CustomRangeField
                            small
                            value={msgData['font_size']}
                            min={100}
                            max={1000}
                            step={10}
                            onChange={(e) => { e.preventDefault(); handleImageMessageListChange(index, { 'font_size': e.target.value }) }}
                        ><strong>% font size</strong></CustomRangeField>

                    </Form.Group>



                </div>


            </Col>

            <Col className="d-none" xs={12}>
                <Stack direction={"horizontal"} gap={2}>
                    {message_index !== 'base_message' &&
                        <>
                            <Form.Group>
                                <Form.Label><strong>Font Style</strong></Form.Label>
                                <Row className="d-flex justify-content-center p-1">
                                    <Col xs={10} className="d-flex justify-content-between ">
                                        <FaBold style={{
                                            color: msgData['bold'] ? 'green' : 'white',
                                            cursor: 'pointer'
                                        }}
                                            onClick={(e) => handleFontStyleChange('bold')} />
                                        <FaItalic onClick={(e) => handleFontStyleChange('italic')}
                                            style={{
                                                cursor: 'pointer',
                                                color: msgData['italic'] ? 'green' : 'white',
                                            }} />
                                        <FaUnderline onClick={(e) => handleFontStyleChange('underline')}
                                            style={{
                                                cursor: 'pointer',
                                                color: msgData['underline'] ? 'green' : 'white',
                                            }} />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Row className="d-flex justify-content-center p-1">
                                <hr />
                                <Col xs={10} className="d-flex justify-content-between">
                                    <FaAlignLeft
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleImageMessageListChange(index, { "text_align": 'left' })} className={msgData['text_align'] === 'left' ? 'text-success' : ''} />
                                    <FaAlignJustify
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={(e) => handleImageMessageListChange(index, { "text_align": 'center' })} className={msgData['text_align'] === 'center' ? 'text-success' : ''} />
                                    <FaAlignRight
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleImageMessageListChange(index, { "text_align": 'right' })} className={msgData['text_align'] === 'right' ? 'text-success' : ''} />
                                </Col>
                                <hr className="my-3" />
                                <Col xs={10} className="d-flex justify-content-between ">
                                    <MdAlignVerticalBottom
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleImageMessageListChange(index, { 'vertical_align': 'end' })} className={msgData['vertical_align'] === 'end' ? 'text-success' : ''} />
                                    <MdAlignVerticalCenter
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={(e) => handleImageMessageListChange(index, { 'vertical_align': 'center' })} className={msgData['vertical_align'] === 'center' ? 'text-success' : ''} />
                                    <MdAlignVerticalTop
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleImageMessageListChange(index, { 'vertical_align': 'baseline' })} className={msgData['vertical_align'] === 'baseline' ? 'text-success' : ''} />
                                </Col>
                            </Row>
                            <hr />
                            <Form.Group>
                                <Form.Label><strong>Font Family</strong></Form.Label>
                                <Form.Select
                                    value={msgData['font_family']}
                                    onChange={(e) => (e.preventDefault, handleImageMessageListChange(index, { 'font_family': e.target.value }))}
                                    aria-label="Default select example"
                                >
                                    {['Helvetica', 'Times New Roman', 'Arial', 'Courier New', 'Georgia', 'Trebuchet MS', 'Tahoma', 'Verdana'].map((v, i) =>
                                        <option
                                            style={{
                                                fontFamily: v
                                            }}
                                            key={i}
                                            value={v}>
                                            {v}
                                        </option>
                                    )}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mt-3">
                                <Form.Label><strong>Font Size</strong></Form.Label>
                                <CustomRangeField
                                    value={msgData['font_size']}
                                    min={100}
                                    max={1000}
                                    step={10}
                                    onChange={(e) => { e.preventDefault(); handleImageMessageListChange(index, { 'font_size': e.target.value }) }}
                                ><strong>%</strong></CustomRangeField>
                                {/* <Form.Range value={msgData['font_size']} min={100} max={1000} step={10} onChange={(e) => (e.preventDefault(), handleImageMessageListChange(index, { 'font_size': e.target.value }))}
                        /> */}
                            </Form.Group>
                            <Form.Group className="my-3">
                                <Form.Label><strong>Font Color</strong></Form.Label>
                                <Row className="d-flex justify-content-between">
                                    <Col className="d-flex justify-content-center">
                                        {
                                            defaultColors.map((c, i) =>
                                                <div
                                                    key={i}
                                                    className="m-1"
                                                    style={{
                                                        backgroundColor: c,
                                                        width: '30px',
                                                        height: '30px',
                                                        borderRadius: '5px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => handleImageMessageListChange(index, { 'font_color': c })}
                                                >
                                                </div>
                                            )
                                        }
                                    </Col>
                                </Row>
                                <div className="mt-2">
                                    <p><strong>Custom</strong></p>
                                    <input style={{ cursor: 'pointer' }} type="color" value={msgData['font_color']} onChange={e => handleImageMessageListChange(index, { "font_color": e.target.value })} />
                                </div>
                            </Form.Group>



                            <hr />
                            <>
                                <Form.Group>
                                    <Form.Label>
                                        <strong>Text outline</strong>
                                    </Form.Label>
                                    <Form.Check // prettier-ignore
                                        type={'radio'}
                                        id={`no-outline`}
                                        label={`None`}
                                        name='outline'
                                        value={'none'}
                                        onChange={(e) => handleOutlineChange(e, 'change_to_none')}
                                        // onClick={(e) => (handleImageMessageListChange(index, 'outline_type', 'none'))}
                                        checked={msgData['outline_type'] === 'none' ? true : false}
                                    />
                                    <Form.Check // prettier-ignore
                                        type={'radio'}
                                        id={`shadow-outline`}
                                        label={`Shadow`}
                                        name='outline'
                                        value={'shadow'}
                                        onChange={(e) => handleOutlineChange(e, 'change_to_blur')}
                                        checked={msgData['outline_type'] === 'shadow' ? true : false}
                                    />
                                    <Form.Check // prettier-ignore
                                        type={'radio'}
                                        id={`outline-outline`}
                                        label={`Outline`}
                                        name='outline'
                                        value={'outline'}
                                        onChange={(e) => handleOutlineChange(e, 'change_to_outline')}
                                        checked={msgData['outline_type'] === 'outline' ? true : false}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label><strong>Outline Width</strong></Form.Label>
                                    {
                                        msgData['outline_type'] === 'outline' ?
                                            <CustomRangeField
                                                value={msgData['outline_width']}
                                                min={0}
                                                max={.1}
                                                step={.005}
                                                onChange={(e) => handleOutlineChange(e, 'outline_width')}
                                            ><strong> px</strong></CustomRangeField>
                                            :
                                            msgData['outline_type'] === 'shadow' ?
                                                <CustomRangeField
                                                    value={msgData['outline_blur']}
                                                    min={0}
                                                    max={1}
                                                    step={.005}
                                                    onChange={(e) => handleOutlineChange(e, "outline_blur")}
                                                ><strong> px</strong></CustomRangeField>
                                                :
                                                <CustomRangeField
                                                    value={0}
                                                    min={0}
                                                    max={1}
                                                    step={.005}
                                                    disabled
                                                ><strong> px</strong></CustomRangeField>
                                    }
                                </Form.Group>
                                {
                                    msgData['outline_type'] !== 'none' &&
                                    <Form.Group>
                                        <Form.Label><strong>Outline Color</strong></Form.Label>
                                        <Row className="d-flex justify-content-between">
                                            <Col className="d-flex justify-content-center">
                                                {
                                                    defaultColors.map((c, i) =>
                                                        <div
                                                            key={i}
                                                            className="m-1"
                                                            style={{
                                                                backgroundColor: c,
                                                                width: '30px',
                                                                height: '30px',
                                                                borderRadius: '5px',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => handleOutlineChange(c, "outline_color")}
                                                        >
                                                        </div>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                        <div className="mt-2">
                                            <p><strong>Custom</strong></p>
                                            <input style={{ cursor: 'pointer' }} type="color" value={msgData['outline_color']} onChange={e => handleOutlineChange(e.target.value, "outline_color")} />
                                        </div>
                                    </Form.Group>
                                }
                            </>
                            <hr />
                            <Form.Group className="mt-3">
                                <Form.Label><strong>Text Angle</strong></Form.Label>
                                {/* <div>{msgData['angle']}°</div> */}
                                <CustomRangeField
                                    value={msgData['angle']}
                                    min={0}
                                    max={359}
                                    step={1}
                                    onChange={(e) => { e.preventDefault(); handleImageMessageListChange(index, { 'angle': e.target.value }) }}
                                ><strong>°</strong></CustomRangeField>

                            </Form.Group>
                            <hr />
                            <Form.Group>
                                <Form.Label><strong>Background Color</strong></Form.Label>
                                <div>
                                    <Row className="d-flex justify-content-between">
                                        <Col className="d-flex justify-content-center">
                                            {
                                                defaultColors.map((c, i) =>
                                                    <div
                                                        key={i}
                                                        style={{
                                                            backgroundColor: c,
                                                            width: '30px',
                                                            height: '30px',
                                                            borderRadius: '5px',
                                                            cursor: 'pointer',
                                                        }}
                                                        className="m-1"
                                                        onClick={() => handleImageMessageListChange(index, { 'background_color': c })}
                                                    >
                                                    </div>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                    <div className="mt-2">
                                        <p><strong>Custom</strong></p>
                                        <input style={{ cursor: 'pointer' }} type="color" value={msgData['background_color']} onChange={(e) => handleImageMessageListChange(index, { 'background_color': e.target.value })} />
                                        <div className="mt-3">
                                            <Form.Group>
                                                <Form.Label><strong>Background Opacity</strong></Form.Label>
                                                {/* <div>{msgData['background_opacity']}</div> */}
                                                <CustomRangeField
                                                    value={msgData['background_opacity']}
                                                    min={0}
                                                    max={100}
                                                    step={1}
                                                    onChange={(e) => handleFontBackgroundColorOpacityChange(e, index)}
                                                ><strong>%</strong></CustomRangeField>
                                                {/*
                                        <Form.Range onChange={(e) => handleFontBackgroundColorOpacityChange(e, index)} value={msgData['background_opacity']} min={0} max={100} step={1} /> */}
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                <Form.Switch
                                    active='true'
                                    className="mt-3"
                                    onClick={(e) => e.target.checked === true ?
                                        handleImageMessageListChange(index, { 'rounded_border': true })
                                        :
                                        handleImageMessageListChange(index, { 'rounded_border': false })
                                    }
                                    label="Rounded border" />
                                <hr />
                                <Form.Switch
                                    active='true'
                                    className="mt-3"
                                    onClick={(e) => e.target.value === 'on' ?
                                        handleImageMessageListChange(index, { 'background_color': 'transparent' })
                                        :
                                        handleImageMessageListChange(index, { 'background_color': 'white' })
                                    }
                                    label="No background color" />
                            </Form.Group>
                        </>
                    }
                    {message_index === 'base_message' &&
                        <>
                            <Form.Group className="my-3">
                                <Form.Label><strong>Font Color</strong></Form.Label>
                                <Row className="d-flex justify-content-between">
                                    <Col className="d-flex justify-content-center">
                                        {
                                            defaultColors.map((c, i) =>
                                                <div
                                                    key={i}
                                                    className="m-1"
                                                    style={{
                                                        backgroundColor: c,
                                                        width: '30px',
                                                        height: '30px',
                                                        borderRadius: '5px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={(e) => handleUpdateBaseMessage(mediaName, { color: c })}
                                                >
                                                </div>
                                            )
                                        }
                                    </Col>
                                </Row>
                                <div className="mt-2">
                                    <p><strong>Custom</strong></p>
                                    <input style={{ cursor: 'pointer' }} type="color" value={msgData['font_color']} onChange={e => handleUpdateBaseMessage(mediaName, { color: e.target.value })} />
                                </div>
                            </Form.Group>
                            <hr />
                            <>
                                <Form.Group>
                                    <Form.Label>
                                        <strong>Text outline</strong>
                                    </Form.Label>
                                    <Form.Check // prettier-ignore
                                        type={'radio'}
                                        id={`no-outline`}
                                        label={`None`}
                                        name='outline'
                                        value={'none'}
                                        onChange={(e) => handleOutlineChange(e, 'change_to_none', true)}
                                        // onClick={(e) => (handleImageMessageListChange(index, 'outline_type', 'none'))}
                                        checked={msgData['outline_type'] === 'none' ? true : false}
                                    />
                                    <Form.Check // prettier-ignore
                                        type={'radio'}
                                        id={`shadow-outline`}
                                        label={`Shadow`}
                                        name='outline'
                                        value={'shadow'}
                                        onChange={(e) => handleOutlineChange(e, 'change_to_blur', true)}
                                        checked={msgData['outline_type'] === 'shadow' ? true : false}
                                    />
                                    <Form.Check // prettier-ignore
                                        type={'radio'}
                                        id={`outline-outline`}
                                        label={`Outline`}
                                        name='outline'
                                        value={'outline'}
                                        onChange={(e) => handleOutlineChange(e, 'change_to_outline', true)}
                                        checked={msgData['outline_type'] === 'outline' ? true : false}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label><strong>Outline Width</strong></Form.Label>
                                    {
                                        msgData['outline_type'] === 'outline' ?
                                            <Form.Range value={msgData['outline_width']} min={0} max={.1} step={.005} onChange={(e) => handleOutlineChange(e, 'outline_width', true)} />
                                            :
                                            msgData['outline_type'] === 'shadow' ?
                                                <Form.Range value={msgData['outline_blur']} min={0} max={1} step={.005} onChange={(e) => handleOutlineChange(e, "outline_blur", true)} />
                                                :
                                                <Form.Range disabled value={0} min={0} max={.1} step={.005} />
                                    }
                                </Form.Group>
                                {
                                    msgData['outline_type'] !== 'none' &&
                                    <Form.Group>
                                        <Form.Label><strong>Outline Color</strong></Form.Label>
                                        <Row className="d-flex justify-content-between">
                                            <Col className="d-flex justify-content-center">
                                                {
                                                    defaultColors.map((c, i) =>
                                                        <div
                                                            key={i}
                                                            className="m-1"
                                                            style={{
                                                                backgroundColor: c,
                                                                width: '30px',
                                                                height: '30px',
                                                                borderRadius: '5px',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => handleOutlineChange(c, "outline_color", true)}
                                                        >
                                                        </div>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                        <div className="mt-2">
                                            <p><strong>Custom</strong></p>
                                            <input style={{ cursor: 'pointer' }} type="color" value={msgData['outline_color']} onChange={e => handleOutlineChange(e.target.value, "outline_color", true)} />
                                        </div>
                                    </Form.Group>
                                }
                            </>
                        </>
                    }
                </Stack>
            </Col>
        </>

    )
};//with forward ref there is a close ) here



export default FontEditorDisplay