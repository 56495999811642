import { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate, useLocation } from 'react-router-dom';
import Body from '../components/Body';
import InputField from '../components/InputField';
import { useApi } from '../contexts/ApiProvider';
import { useFlash } from '../contexts/FlashProvider';
import FormButtonBlock from '../components/FormButtonBlock';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

export default function EditUserPage() {
  const [isProcessing, setIsProcessing] = useState(false)
  const [formErrors, setFormErrors] = useState({});
  const passwordField = useRef();
  const password2Field = useRef();
  const navigate = useNavigate();
  const { search } = useLocation();
  const api = useApi();
  const flash = useFlash();
  const token = new URLSearchParams(search).get('token');





  useEffect(() => {
    if (!token) {
      navigate('/');
    }
    else {
      passwordField.current.focus();
    }
  }, [token, navigate]);

  const onSubmit = async (event) => {
    event.preventDefault();
    setFormErrors({});
    try {
      setIsProcessing(true);

      if (passwordField.current.value !== password2Field.current.value) {
        setFormErrors({ password2: "Passwords do not match" });
      }
      else {
        const response = await api.put('/tokens/reset', {
          token,
          password: passwordField.current.value,
          password2: password2Field.current.value,
        });
        if (response.ok) {
          setIsProcessing(false);
          setFormErrors({});
          flash('Your password has been reset.', 'success');
          navigate('/login');
        }
        else {
          setIsProcessing(false);
          if ('json' in response.body.errors) {
            setFormErrors(response.body.errors.json);
          }

          flash(response.body.errors.json.message, 'danger');


        }
      }
    } catch (error) {
      setIsProcessing(false)
    }
  };

  return (
    <Body>

      <Row className="d-flex justify-content-center">
        <Col xs={12} lg={8}>
          <h1 className="mb-3">Reset Password</h1>
          <Form onSubmit={onSubmit}>
            <InputField
              name="password" label="New Password" type="password"
              error={formErrors.password} fieldRef={passwordField} />
            <InputField
              name="password2" label="New Password Again" type="password"
              error={formErrors && 'password2' in formErrors && formErrors.password2} fieldRef={password2Field} />
            <FormButtonBlock isProcessing={isProcessing} text={"Reset Password"} />
          </Form>
        </Col>
      </Row>

    </Body >
  );
}