import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { IoEye } from "react-icons/io5";
import { IoEyeOffSharp } from "react-icons/io5";



export default function InputField(
  { name, label, type, placeholder, error, fieldRef, formText, as, additionalFields, rows, onChange, value, formLabel, character, charRange, hideBottomMargin }

) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Form.Group controlId={name} className={`${hideBottomMargin ? 'mb-0' : 'mb-3'} InputField`}>
      {formLabel && <Form.Label>{formLabel}</Form.Label>}



      <FloatingLabel
        controlId="floatingInput"
        label={label}

      >


        <Form.Control
          type={showPassword ? 'text' : type || 'text'}
          placeholder={"placeholder"}
          ref={fieldRef}
          as={as}
          onChange={onChange}
          {...additionalFields}
          // rows={40}
          style={type === 'textarea' ? { height: '150px' } : { height: '4em' }}
          // data-bs-theme="light"
          value={value}


        />

        {
          type && type === 'password' && showPassword &&

            <IoEye onClick={() => setShowPassword(false)} size={24} style={{
              position: "absolute",
              right: "2%",
              top: "50%",
              zIndex: "100",
              cursor: "pointer",
            }} />
        }
        {type && type === 'password' && !showPassword &&
          <IoEyeOffSharp onClick={() => setShowPassword(true)} size={24} style={{
            position: "absolute",
            right: "2%",
            top: "50%",
            zIndex: "100",
            cursor: "pointer",
          }} />

        }




        {/* <Form.Control autoFocus type="email" placeholder="name@example.com" /> */}
      </FloatingLabel>


      <div>{formText && <Form.Text>{formText}</Form.Text>}</div>

      {
        charRange ?
          value && value.length < charRange['max'] && charRange['min'] <= value.length ?
            <div style={{ textAlign: 'right' }}><Form.Text className="text-secondary">{value && value.length}</Form.Text></div>
            :
            <div style={{ textAlign: 'right' }}><Form.Text className="text-danger">{value && value.length}</Form.Text></div>
          :
          <span></span>
      }
      <div><Form.Text className="text-danger">{error}</Form.Text></div>
    </Form.Group>
    // <Form.Group controlId={name} className="InputField">
    //   {label && <Form.Label>{label}</Form.Label>}
    //   <Form.Control
    //     type={type || 'text'}
    //     placeholder={placeholder}
    //     ref={fieldRef}
    //     as={as}
    //     {...additionalFields}
    //   />
    //   <div>{formText && <Form.Text>{formText}</Form.Text>}</div>
    //   <div><Form.Text className="text-danger">{error}</Form.Text></div>
    // </Form.Group>
  );
}