import React from 'react'
import Popover from 'react-bootstrap/Popover';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useImageBuilder } from '../contexts/ImageBuilderProvider';
import CustomRangeField from './CustomRangeField';
import Stack from 'react-bootstrap/esm/Stack';
import { GoSidebarExpand } from "react-icons/go";
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import PopoverBody from 'react-bootstrap/esm/PopoverBody';
import { MdOutlineCenterFocusWeak } from "react-icons/md";
import { SlSizeFullscreen } from "react-icons/sl";
import { SlSizeActual } from "react-icons/sl";
import { IoMoveOutline } from "react-icons/io5";
import { MdOutlineLightMode } from "react-icons/md";
import { CiDark } from "react-icons/ci";
import { IoColorFill } from "react-icons/io5";
import EditorDisplayButton from './EditorDisplayButton';
import NewCampaignImageBackground from './NewCampaignImageBackground';
import { GiResize } from "react-icons/gi";
import ClickHoverPopover from './ClickHoverPopover';
import { MdOutlineTextFormat } from "react-icons/md";
import { AiOutlineColumnWidth } from "react-icons/ai";
function ImageEditorDisplay({ mediaName, image_data, handleFileInputChange, isPost, }) {

    const { handleUpdateRespData, handleFontBackgroundColorOpacityChange, defaultColors, handleToggleImgEditDisplay, handleOutlineChange, handleUpdateBaseMessage } = useImageBuilder()



    const index = false

    const BUTTON_BAR_ICON_SIZE = 24
    return (
        <Row>
            <Col xs={12} className="d-flex justify-content-start mt-3 mb-1">

                <Button size={'sm'} onClick={() => handleToggleImgEditDisplay(mediaName)} variant="primary">
                    <GoSidebarExpand size={18} />
                    <span className="mx-2"><strong>Finish edit</strong></span>
                </Button>

            </Col>
            <Col xs={12}>


                <div style={{ gap: '10px' }} xs={12} className="mt-2 d-flex flex-wrap align-items-center justify-content-sm-start justify-content-lg-center" role="toolbar" aria-label="Text formatting toolbar">
                    <ButtonGroup aria-label="Basic text formatting">





                        <EditorDisplayButton body={"Image Cover"} onClick={() => handleUpdateRespData(mediaName, { 'background_size': 'cover', background_img_scale: 1 })}>

                            <SlSizeFullscreen

                                size={BUTTON_BAR_ICON_SIZE}
                                className={`${image_data['image']['background_size'] === 'cover' ? 'text-success' : 'text-white'}`}

                            />

                        </EditorDisplayButton>




                        <EditorDisplayButton header={"Actual size image"} body={"The image will show its true dimensions. "} onClick={() => handleUpdateRespData(mediaName, { 'background_size': 'contain', background_img_scale: 1 })}>

                            <SlSizeActual

                                size={BUTTON_BAR_ICON_SIZE}
                                className={`${image_data['image']['background_size'] === 'contain' ? 'text-success' : 'text-white'}`}
                            />

                        </EditorDisplayButton>
                        <EditorDisplayButton body={"Center the image"} onClick={() => handleUpdateRespData(mediaName, { 'background_img_y': '0', 'background_img_x': '0' })}>

                            <MdOutlineCenterFocusWeak

                                size={BUTTON_BAR_ICON_SIZE}
                                className={`${(parseInt(image_data['image']['background_img_y']) === 0) && (parseInt(image_data['image']['background_img_x']) === 0) ? 'text-success' : 'text-white'}`}
                            />

                        </EditorDisplayButton>

                        <ClickHoverPopover
                            color="outline-secondary"
                            icon={<GiResize style={{ padding: '.2em', borderRadius: '.25em' }} size={BUTTON_BAR_ICON_SIZE} />}
                            tooltipMsg="Scale image"
                        >

                            <Popover id="popover-basic4">
                                <Popover.Header>Background Image Size</Popover.Header>
                                <PopoverBody>

                                    <Form.Group>

                                        <CustomRangeField
                                            hideValue
                                            value={image_data['image']['background_img_scale']}
                                            min={.1}
                                            max={3}
                                            step={.1}
                                            onChange={(e) => handleUpdateRespData(mediaName, { background_img_scale: e.target.value })} //TODO: add change to transform x and y
                                        >
                                            Size
                                        </CustomRangeField>



                                    </Form.Group>
                                    <Form.Group>
                                        <Button size="sm" onClick={() => handleUpdateRespData(mediaName, { background_img_scale: 1 })} variant="secondary">Reset</Button>
                                    </Form.Group>


                                </PopoverBody>
                            </Popover>


                        </ClickHoverPopover>



                        <ClickHoverPopover
                            color="outline-secondary"
                            icon={<IoMoveOutline style={{ padding: '.2em', borderRadius: '.25em' }} size={BUTTON_BAR_ICON_SIZE} />}
                            tooltipMsg="Crop & move image"
                        >

                            <Popover id="popover-basic3">
                                <Popover.Header>Background Image Postion</Popover.Header>
                                <PopoverBody>

                                    <Form.Group>

                                        <CustomRangeField
                                            hideValue
                                            value={image_data['image']['background_img_x']}
                                            min={-100}
                                            max={100}
                                            step={1}
                                            onChange={(e) => handleUpdateRespData(mediaName, { background_img_x: e.target.value })} //TODO: add change to transform x and y
                                        >Horizontal</CustomRangeField>

                                        <CustomRangeField
                                            hideValue
                                            value={image_data['image']['background_img_y']}
                                            min={-100}
                                            max={100}
                                            step={1}
                                            onChange={(e) => handleUpdateRespData(mediaName, { background_img_y: e.target.value })} //TODO: add change to transform x and y
                                        >Vertical</CustomRangeField>



                                    </Form.Group>

                                </PopoverBody>
                            </Popover>


                        </ClickHoverPopover>






                    </ButtonGroup>

                    <ClickHoverPopover
                        icon={<MdOutlineLightMode style={{ padding: '.2em', borderRadius: '.25em' }} size={BUTTON_BAR_ICON_SIZE} />}

                        tooltipMsg={"Background contrast"}>
                        <Popover id="popover-basic5">
                            <Popover.Header>Background Contrast</Popover.Header>
                            <PopoverBody>
                                <Form.Group>

                                    <div className="d-flex w-100 justify-content-between">

                                        <MdOutlineLightMode style={{ padding: '.2em', borderRadius: '.25em' }} size={BUTTON_BAR_ICON_SIZE} />
                                        <CiDark style={{ padding: '.2em', borderRadius: '.25em' }} size={BUTTON_BAR_ICON_SIZE} />

                                    </div>
                                    <CustomRangeField
                                        hideValue
                                        value={image_data['image']['background_opacity_filter']}
                                        min={0}
                                        max={1}
                                        step={.01}
                                        onChange={(e) => handleUpdateRespData(mediaName, { background_opacity_filter: e.target.value })} //TODO: add change to transform x and y
                                    ></CustomRangeField>


                                </Form.Group>
                            </PopoverBody>
                        </Popover>

                    </ClickHoverPopover>




                    <ClickHoverPopover
                        icon={<IoColorFill style={{ padding: '.2em', borderRadius: '.25em' }} size={BUTTON_BAR_ICON_SIZE} />}

                        tooltipMsg={"Background color"}>


                        <Popover id="popover-basic3">
                            <Popover.Header>Background Color</Popover.Header>
                            <PopoverBody>
                                <Form.Group>
                                    <Form.Group>
                                        <Tabs
                                            id="controlled-tab-example"
                                            activeKey={image_data['image']['is_gradient'] ? 'gradient' : 'solid'}
                                            fill
                                            onSelect={() => handleUpdateRespData(mediaName, { is_gradient: image_data['image']['is_gradient'] === true ? false : true })}
                                        >
                                            <Tab eventKey="solid" title="Solid" className="py-3" >

                                                <Stack gap={3}>
                                                    <Form.Group>

                                                        <Row className="d-flex justify-content-between">
                                                            {
                                                                defaultColors.map((c, i) =>
                                                                    <Col key={i} className="d-flex justify-content-center p-1">
                                                                        <div
                                                                            style={{
                                                                                backgroundColor: c,
                                                                                width: '60px',
                                                                                height: '30px',
                                                                                borderRadius: '5px',
                                                                                cursor: 'pointer',

                                                                            }}
                                                                            onClick={() => handleUpdateRespData(mediaName, { background_color: c })}
                                                                        >
                                                                        </div>
                                                                    </Col>
                                                                )
                                                            }
                                                        </Row>
                                                    </Form.Group >
                                                    <Col xs={{ span: "auto", }}>
                                                        <p className="mt-2 mb-1"><strong>Custom</strong></p>
                                                        <input style={{ cursor: 'pointer' }} type="color" value={image_data['image']['background_color']} onChange={e => handleUpdateRespData(mediaName, { 'background_color': e.target.value })} />
                                                    </Col>
                                                    <Form.Group>
                                                        <Form.Label><strong>Opacity</strong></Form.Label>
                                                        {/* <div>{image_data['image']['background_opacity']}</div> */}

                                                        <CustomRangeField

                                                            value={image_data['image']['background_color_opacity']}
                                                            min={0}
                                                            max={100}
                                                            step={1}
                                                            onChange={(e) => handleFontBackgroundColorOpacityChange(e, index, mediaName)}
                                                        ><strong>%</strong></CustomRangeField>

                                                        {/* 
    <Form.Range onChange={(e) => handleFontBackgroundColorOpacityChange(e, index)} value={image_data['image']['background_opacity']} min={0} max={100} step={1} /> */}
                                                    </Form.Group>
                                                </Stack>
                                            </Tab>
                                            <Tab eventKey="gradient" title="Gradient">
                                                <Stack gap={3}>
                                                    <Col xs={{ span: "auto", }}>
                                                        <p className="mt-2 mb-1"><strong>Start color</strong></p>
                                                        <input style={{ cursor: 'pointer' }} type="color" value={image_data['image']['gradient_color_1']} onChange={e => handleUpdateRespData(mediaName, { 'gradient_color_1': e.target.value })} />
                                                    </Col>
                                                    <Col xs={{ span: "auto", }}>
                                                        <p className="mt-2 mb-1"><strong>End color</strong></p>
                                                        <input style={{ cursor: 'pointer' }} type="color" value={image_data['image']['gradient_color_2']} onChange={e => handleUpdateRespData(mediaName, { 'gradient_color_2': e.target.value })} />
                                                    </Col>

                                                    <div className="my-1">
                                                        <CustomRangeField
                                                            hideValue
                                                            value={image_data['image']['gradient_deg']}
                                                            min={0}
                                                            max={359}
                                                            step={1}
                                                            onChange={(e) => handleUpdateRespData(mediaName, { 'gradient_deg': e.target.value })} //TODO: add change to transform x and y
                                                        ><strong>Gradient Direction</strong></CustomRangeField>
                                                    </div>


                                                </Stack>


                                            </Tab>


                                        </Tabs>



                                    </Form.Group>


                                    <Form.Switch
                                        // active
                                        className="mt-3"
                                        onClick={(e) => e.target.value === 'on' &&
                                            handleUpdateRespData(mediaName, { 'background_color': 'white', 'is_gradient': false })
                                        }
                                        // :
                                        // handleBackgroundColorChange('white')



                                        label="Clear background color" />


                                </Form.Group>



                            </PopoverBody>
                        </Popover>


                    </ClickHoverPopover>




                    {
                        isPost &&
                        <>

                            <ClickHoverPopover
                                icon={<MdOutlineTextFormat style={{ padding: '.2em', borderRadius: '.25em' }} size={BUTTON_BAR_ICON_SIZE} />}

                                tooltipMsg={"Text outline"}>


                                <Popover id="popover-basic3">
                                    <Popover.Header>Text outline</Popover.Header>
                                    <PopoverBody>
                                        <>

                                            <Form.Group>
                                                <Form.Check // prettier-ignore

                                                    type={'radio'}
                                                    id={`no-outline`}
                                                    label={`None`}
                                                    name='outline'
                                                    value={'none'}
                                                    onChange={(e) => handleOutlineChange(e, 'change_to_none', true, mediaName, index)}
                                                    // onClick={(e) => (handleImageMessageListChange(index, 'outline_type', 'none'))}
                                                    checked={image_data['base_message']['outline_type'] === 'none' ? true : false}
                                                />
                                                <Form.Check // prettier-ignore
                                                    type={'radio'}
                                                    id={`shadow-outline`}
                                                    label={`Shadow`}
                                                    name='outline'
                                                    value={'shadow'}
                                                    onChange={(e) => handleOutlineChange(e, 'change_to_blur', true, mediaName, index)}
                                                    checked={image_data['base_message']['outline_type'] === 'shadow' ? true : false}
                                                />
                                                <Form.Check // prettier-ignore
                                                    type={'radio'}
                                                    id={`outline-outline`}
                                                    label={`Outline`}
                                                    name='outline'
                                                    value={'outline'}
                                                    onChange={(e) => handleOutlineChange(e, 'change_to_outline', true, mediaName, index)}
                                                    checked={image_data['base_message']['outline_type'] === 'outline' ? true : false}
                                                />

                                            </Form.Group>





                                            <Form.Group className="my-3">
                                                <Form.Label><strong>Outline Width</strong></Form.Label>



                                                {
                                                    image_data['base_message']['outline_type'] === 'outline' ?

                                                        <CustomRangeField
                                                            value={image_data['base_message']['outline_width']}
                                                            min={0}
                                                            max={.1}
                                                            step={.005}
                                                            onChange={(e) => handleOutlineChange(e, 'outline_width', true, mediaName, index)}
                                                        />

                                                        // <Form.Range value={image_data['base_message']['outline_width']} min={0} max={.1} step={.005} onChange={(e) => handleOutlineChange(e, 'outline_width', true, mediaName, index)} />
                                                        :
                                                        image_data['base_message']['outline_type'] === 'shadow' ?
                                                            <CustomRangeField
                                                                value={image_data['base_message']['outline_blur']}
                                                                min={0}
                                                                max={1}
                                                                step={.005}
                                                                onChange={(e) => handleOutlineChange(e, "outline_blur", true, mediaName, index)}
                                                            />


                                                            :
                                                            <CustomRangeField
                                                                value={image_data['base_message']['outline_blur']}
                                                                min={0}
                                                                max={1}
                                                                step={.005}
                                                                disabled
                                                            />


                                                }


                                            </Form.Group>


                                            {
                                                image_data['base_message']['outline_type'] !== 'none' &&
                                                <Form.Group>
                                                    <Form.Label><strong>Outline Color</strong></Form.Label>


                                                    <Row className="d-flex justify-content-between">
                                                        <Col className="d-flex justify-content-center">
                                                            {
                                                                defaultColors.map((c, i) =>
                                                                    <div
                                                                        key={i}
                                                                        className="m-1"
                                                                        style={{
                                                                            backgroundColor: c,
                                                                            width: '30px',
                                                                            height: '30px',
                                                                            borderRadius: '5px',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        onClick={() => handleOutlineChange(c, "outline_color", true, mediaName, index)}
                                                                    >
                                                                    </div>

                                                                )
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <div className="mt-2">
                                                        <p><strong>Custom</strong></p>
                                                        <input style={{ cursor: 'pointer' }} type="color" value={image_data['base_message']['outline_color']} onChange={e => handleOutlineChange(e.target.value, "outline_color", true, mediaName, index)} />
                                                    </div>



                                                </Form.Group>
                                            }

                                        </>




                                    </PopoverBody>
                                </Popover>


                            </ClickHoverPopover>


                            <ClickHoverPopover
                                icon={<MdOutlineTextFormat style={{ padding: '.2em', borderRadius: '.25em' }} size={BUTTON_BAR_ICON_SIZE} />}

                                tooltipMsg={"Username color"}>


                                <Popover id="popover-basic3">
                                    <Popover.Header>Username text color</Popover.Header>
                                    <PopoverBody>
                                        <>

                                            <Form.Group className="mb-3">

                                                <Row className="d-flex justify-content-between">
                                                    <Col className="d-flex justify-content-center">
                                                        {
                                                            defaultColors.map((c, i) =>
                                                                <div
                                                                    key={i}
                                                                    className="m-1"
                                                                    style={{
                                                                        backgroundColor: c,
                                                                        width: '30px',
                                                                        height: '30px',
                                                                        borderRadius: '5px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={(e) => handleUpdateBaseMessage(mediaName, { color: c })}
                                                                >
                                                                </div>

                                                            )
                                                        }
                                                    </Col>
                                                </Row>
                                                <div className="mt-2">
                                                    <p><strong>Custom</strong></p>
                                                    <input style={{ cursor: 'pointer' }} type="color" value={image_data['image']['font_color']} onChange={e => handleUpdateBaseMessage(mediaName, { color: e.target.value })} />
                                                </div>



                                            </Form.Group>


                                        </>




                                    </PopoverBody>
                                </Popover>


                            </ClickHoverPopover>


                            <ClickHoverPopover
                                icon={<AiOutlineColumnWidth style={{ padding: '.2em', borderRadius: '.25em' }} size={BUTTON_BAR_ICON_SIZE} />}

                                tooltipMsg={"Message Width"}>


                                <Popover id="popover-basic3">
                                    <Popover.Header>Message Width</Popover.Header>
                                    <PopoverBody>
                                        <>

                                            <div >
                                                <div>

                                                    <Form.Group>
                                                        <CustomRangeField
                                                            value={image_data['base_message']['message_width']}
                                                            min={6}
                                                            max={12}
                                                            step={1}
                                                            onChange={(e) => handleUpdateBaseMessage(mediaName, { message_width: e.target.value })}
                                                        // onChange={(e) => handleMessageWidthChange(e, message_index)}
                                                        />
                                                    </Form.Group>

                                                </div>
                                            </div>


                                        </>




                                    </PopoverBody>
                                </Popover>


                            </ClickHoverPopover>




                        </>

                    }





                </div>


            </Col>
            <Col xs={12}>
                <NewCampaignImageBackground
                    hideGear
                    isUpload
                    image_data={image_data}
                    mediaName={mediaName}
                    handleFileInputChange={handleFileInputChange}
                    message_index="base_message"
                />
            </Col>


        </Row>

    )
};//with forward ref there is a close ) here



export default ImageEditorDisplay