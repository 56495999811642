import React, { forwardRef } from 'react'
import Popover from 'react-bootstrap/Popover';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import CustomRangeField from './CustomRangeField';





const ImageMsgSettingEditor = forwardRef(({ msgData, index, message_index, image_data, hideFullsize,  handleImageMessageListChange, handleFullScreenChange,defaultColors }, fieldRef) => {



    const handleFontBackgroundColorOpacityChange = (e, index) => {
        let opacity = e.target.value;
        let updated_resp_data;
        let currentColor;
        let color;


        if (parseInt(opacity) <= 99) {

            if (opacity <= 9) {
                opacity = `0${opacity}`
            }


            if (msgData['background_color'].length === 9) {
                currentColor = msgData['background_color'].slice(0, 7); // Remove the alpha value
                color = currentColor + opacity

            }
            else {
                color = msgData['background_color'] + `${opacity}`

            }

            handleImageMessageListChange(index, { "background_opacity": opacity, "background_color": color })

        }
        if (parseInt(opacity) === 100) {
            currentColor = msgData['background_color'].slice(0, 7); // Remove the alpha value
            color = currentColor;
            handleImageMessageListChange(index, { "background_opacity": opacity, "background_color": color })



        }
    }



    return (
        <Popover ref={fieldRef} id="popover-basic">
            <Popover.Body>


                {
                    !hideFullsize &&
                    <>

                        <div>
                            <Form.Group>
                                <Form.Label><strong>Is background image</strong></Form.Label>
                                <Form.Select
                                    value={image_data.image.fullscreen ? 'yes' : 'no'}
                                    onChange={(e) => handleFullScreenChange(e, message_index)}
                                // aria-label="Default select example"
                                >
                                    {['yes', 'no'].map((v, i) =>
                                        <option key={i} value={v}>
                                            {v}
                                        </option>
                                    )}
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <hr />
                    </>
                }


                <Form.Group>
                    <Form.Label><strong>Image Position</strong></Form.Label>
                    <Form.Select
                        value={msgData['image_position']}
                        onChange={(e) => handleImageMessageListChange(index, { 'image_position': e.target.value })}
                        aria-label="Default select example"
                    >
                        {['center', 'right', 'left', 'bottom', 'top'].map((v, i) =>
                            <option key={i} value={v}>
                                {v}
                            </option>
                        )}
                    </Form.Select>
                </Form.Group>






                <hr />


                <Form.Group>
                    <Form.Label><strong>Image Size</strong></Form.Label>
                    <Form.Select
                        value={msgData['image_size']}
                        onChange={(e) => handleImageMessageListChange(index, { 'image_size': e.target.value })}
                        aria-label="Default select example"
                    >
                        {['cover', 'contain'].map((v, i) =>
                            <option key={i} value={v}>
                                {v}
                            </option>
                        )}
                    </Form.Select>
                </Form.Group>

                <hr />

                <Form.Group>
                    <Form.Label><strong>Image Repeat</strong></Form.Label>
                    <Form.Select
                        value={msgData['image_repeat']}
                        onChange={(e) => handleImageMessageListChange(index, { 'image_repeat': e.target.value })}

                        aria-label="Default select example"
                    >
                        {['no-repeat', 'repeat'].map((v, i) =>
                            <option key={i} value={v}>
                                {v}
                            </option>
                        )}
                    </Form.Select>
                </Form.Group>


                <Form.Group className="mt-3">
                    <Form.Label><strong>Angle</strong></Form.Label>

                    <div>{msgData['angle']}°</div>
                    <Form.Range value={msgData['angle']} min={0} max={359} step={1} onChange={(e) => (e.preventDefault(), handleImageMessageListChange(index, { 'angle': e.target.value }))}
                    />
                </Form.Group>

                <Form.Group className="d-flex align-items-center mx-auto">
                    <Form.Switch
                        active
                        className="mt-3"
                        onClick={(e) => e.target.checked === true ?
                            handleImageMessageListChange(index, { 'x_scale': -1 })
                            :
                            handleImageMessageListChange(index, { 'x_scale': 1 })
                        }

                        label="Flip x" />
                    <Form.Switch

                        active
                        className="mt-3 mx-2"
                        onClick={(e) => e.target.checked === true ?
                            handleImageMessageListChange(index, { 'y_scale': -1 })
                            :
                            handleImageMessageListChange(index, { 'y_scale': 1 })
                        }

                        label="Flip y" />
                </Form.Group>




                {/* {message_index === 'base_message' && !image_data.image.fullscreen &&
                    <div>

                        <hr />
                        <div>
                            <Form.Group>
                                <Form.Label><strong>Message Width</strong></Form.Label>
                                <div>{messageWidth}</div>
                                <Form.Range value={messageWidth} min={6} max={12} step={1} onChange={(e) => handleMessageWidthChange(e, message_index)} />
                            </Form.Group>

                        </div>
                    </div>

                } */}
                <>
                    <hr />
                    <Form.Group>
                        <Form.Label><strong>Background Color</strong></Form.Label>

                        <Form.Group>

                            <Row className="d-flex justify-content-between">
                                {
                                    defaultColors.map((c, i) =>
                                        <Col className="d-flex justify-content-center p-1">
                                            <div
                                                style={{
                                                    backgroundColor: c,
                                                    width: '60px',
                                                    height: '30px',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer',

                                                }}
                                                onClick={() => handleImageMessageListChange(index, { 'background_color': c })}
                                            >
                                            </div>
                                        </Col>
                                    )
                                }


                            </Row>





                        </Form.Group >
                        <Col xs={{ span: "auto", }}>
                            <p className="mt-2 mb-1"><strong>Custom</strong></p>
                            <input style={{ cursor: 'pointer' }} type="color" value={image_data['image']['background_color']} onChange={e => handleImageMessageListChange(index, { 'background_color': e.target.value })} />
                    
                        </Col>
                        <div className="mt-3">

                            <Form.Group>
                                <Form.Label><strong>Background Opacity</strong></Form.Label>
                                {/* <div>{msgData['background_opacity']}</div> */}

                                <CustomRangeField
                                    value={msgData['background_opacity']}
                                    min={0}
                                    max={100}
                                    step={1}
                                    onChange={(e) => handleFontBackgroundColorOpacityChange(e, index)}
                                ><strong>%</strong></CustomRangeField>

                                {/* 
<Form.Range onChange={(e) => handleFontBackgroundColorOpacityChange(e, index)} value={msgData['background_opacity']} min={0} max={100} step={1} /> */}
                            </Form.Group>

                        </div>
                        <Form.Switch
                            active='true'
                            className="mt-2"
                            onClick={(e) => e.target.checked === true ?
                                handleImageMessageListChange(index, { 'rounded_border': true })
                                :
                                handleImageMessageListChange(index, { 'rounded_border': false })
                            }
                            label="Rounded border" />
                        <Form.Switch
                            active
                            className="mt-3"
                            onClick={(e) => e.target.value === 'on' &&
                                handleImageMessageListChange(index, { 'background_color': '#FFFFFF' })

                                // :
                                // handleBackgroundColorChange('white')

                            }

                            label="No background color" />

                    </Form.Group>




                </>








            </Popover.Body>
        </Popover >


    )
},);

export default ImageMsgSettingEditor