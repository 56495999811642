import React, { useState } from 'react';
// import { FaCopy } from "react-icons/fa";
import { FcLink } from "react-icons/fc";
import { FcAbout } from "react-icons/fc";

function CopyCampaign({ campaignId, blurb }) {
    const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/campaign/${campaignId}`;
    const [copyStatus, setCopyStatus] = useState(blurb ? "Copy blurb" : "Copy link");

    const handleCopyClick = async () => {
        try {
            if (blurb) {
                await navigator.clipboard.writeText(`Contribute to this campaign. If not enough is raised to purchase media, you are not charged. ${baseUrl}`);
            }
            else {
                await navigator.clipboard.writeText(baseUrl);
            }
            setCopyStatus(blurb ? "Blurb copied" : "Link copied");
        } catch (error) {
            console.error('Failed to copy:', error);
        }
    };

    return (

        <div>
            {
                blurb ?


                    <div>
                        <div style={{ cursor: 'pointer', textAlign: 'center' }} onClick={handleCopyClick}>
                            <FcAbout size={50} color={copyStatus === "Blurb copied" ? "green" : "white"} />
                        </div>
                        {
                            copyStatus === "Blurb copied" ?
                                <div className="text-success">
                                    <strong>{copyStatus}</strong>
                                </div>
                                :
                                <div >
                                    <strong>{copyStatus}</strong>
                                </div>
                        }
                    </div>


                    :

                    <div>
                        <div style={{ cursor: 'pointer', textAlign: 'center' }} onClick={handleCopyClick}>
                            <FcLink size={50} color={copyStatus === "Link copied" ? "green" : "white"} />
                        </div>
                        {
                            copyStatus === "Link copied" ?
                                <div className="text-success">
                                    <strong>{copyStatus}</strong>
                                </div>
                                :
                                <div >
                                    <strong>{copyStatus}</strong>
                                </div>
                        }
                    </div>


            }
        </div>

    );
}

export default CopyCampaign;
