import React, { useRef, useState, useEffect } from 'react'
import { FcPanorama } from "react-icons/fc";
import MediaSimulation from './MediaSimulation'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useApi } from '../contexts/ApiProvider';
import { useFlash } from '../contexts/FlashProvider';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PostMessageMedia from './PostMessageMedia';
import UploadMedia from './UploadMedia';
import TextToImage from './TextToImage';
import CustomMedia from './CustomMedia';
import { useImageBuilder } from '../contexts/ImageBuilderProvider';
import Collapse from 'react-bootstrap/Collapse';
import '../styles/CampaignMediaComponentStyles.css'
import '../styles/NewCampaignSidebarLayout.css'
import FontEditorDisplay from './FontEditorDisplay';
import TextAreaField from './TextAreaField';
import NewCampaignImageBackground from './NewCampaignImageBackground';
import TabEditorBody from './TabEditorBody';


function CampaignMediaComponent({ image_data, handleUpdateRespData, mediaName, addMediaRef, }) {

    const quillRef = useRef(null);
    const { handleImageMessageListContextChange, handleMessageChange, handleUpdateBaseMessage, handleImgHoverUpdate, handleToggleImgEditDisplay, imgEditDisplay } = useImageBuilder()
    const api = useApi();
    const flash = useFlash();
    const [value, setValue] = useState('');
    const [activeEditMessage, setActiveEditMessage] = useState(false);
    const [imageHover, setImageHover] = useState(false);
    const [height, setHeight] = useState(0);
    const [imgWidth, setImgWidth] = useState(0);
    const [minFontSize, setMinFontSize] = useState()
    const [tabKey, setTabKey] = useState('externalUpload');
    const mediaRef = useRef();




    const handleFileInputChange = async (event, index, onComplete) => {

        const file = event.target.files[0];
        const reader = new FileReader();

        const allowedExtensions = ['jpg', 'png', 'jpeg', 'webp']; // Allowed file extensions
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
            flash(`Invalid file type. Allowed types: ${allowedExtensions.join(', ')}`, 'warning');
            onComplete();
            return;
        }


        reader.onloadstart = () => {

        };


        reader.onloadend = () => {



            // Create new Image object
            const img = new Image();
            img.onload = function () {
                // Calculate aspect ratio
                const aspectRatio = img.naturalWidth / img.naturalHeight;

                (async () => {

                    const response = await api.post(`/new_campaign/process-background-image`, JSON.stringify({ image: reader.result, media_type: mediaName }));
                    if (response.ok) {

                        const updated_resp_data = {
                            ...image_data.image,
                            isLoading: false,
                            url: response.body.url,
                            filename: file.name,
                            image_aspect_ratio: aspectRatio,
                        }

                        handleUpdateRespData(mediaName, updated_resp_data);
                        onComplete()

                    }
                    else {
                        onComplete()


                        'body' in response && 'errors' in response.body ?
                            flash(response.body.errors.json.message, 'danger')
                            :
                            flash('Error with request', 'danger')

                    }

                })();




            };
            img.src = reader.result; // Set src to initiate image loading

        };



        if (file) {
            reader.readAsDataURL(file);

        }
    };

    // TODO: MAKE SURE THAT THE IMAGES ARE IN B64



    const handleFileAIInputChange = async (url, mediaName) => {

        try {

            const updated_resp_data = {
                url: url
            }



            handleUpdateRespData(mediaName, updated_resp_data);





        } catch (error) {
            console.error('Error converting image to base64:', error);
        }










    };




    const handleImageMessageListChange = (index, updates) => {
        handleImageHover(true);

        handleImageMessageListContextChange(mediaName, index, updates)


    }

    useEffect(() => {

        height && setMinFontSize(parseInt(.08 * height))

    }, [height])



    useEffect(() => {
        if (imgEditDisplay && Object.hasOwn(imgEditDisplay, mediaName)) {
            imgEditDisplay[mediaName] === true && setActiveEditMessage(false)
        }

    }, [imgEditDisplay])




    const handleResize = (mediaRef) => {

        if (mediaRef && mediaRef.current) {
            let height = mediaRef.current.clientHeight
            if (height === 0) {
                console.warn('handle resize is 0')
                // alert('height is 0')
                return
            }

            setHeight(() => height);
            setImgWidth(() => mediaRef.current.clientWidth);



        }

    };


    const handleEmojiUpdate = (e, message_id, mediaName) => {
        e.preventDefault();
        //TODO: you need to modify the handle Update resp data to update the context by removing the copy of hte previous state and only including the data key value pair that you need. 


        if (message_id === 'base') {

            // const updated_resp_data = {
            //     ...image_data,
            //     base_message: {
            //         ...image_data.base_message,
            //         message: image_data.base_message.message ? image_data.base_message.message + e.target.textContent : e.target.textContent
            //     }

            // };
            const data = {

                message: image_data.base_message.message ? image_data.base_message.message + e.target.textContent : e.target.textContent


            };
            handleUpdateBaseMessage(mediaName, data)
            // handleUpdateRespData(mediaName, updated_resp_data);
            const quill = quillRef.current.getEditor(); // Get the Quill editor instance
            const range = quill.getSelection(); // Get the current selection range

            const length = quill.getLength();

            // Get the last line and its starting index
            const [lastLine] = quill.getLine(length);
            const lastLineIndex = quill.getIndex(lastLine);
            quill.insertText(lastLineIndex + lastLine.length() - 1, e.target.textContent);


        }
        else {
            return;
        }
    }



    const handleAIImageSubmit = () => {

        return (async () => {
            const response = await api.post(`/new_campaign/new-ai-image`, JSON.stringify({
                ai_message: image_data.image.ai_message,
                ai_is_hd: image_data.image.ai_is_hd,
                ai_quality: image_data.image.ai_is_hd,
                ai_style: image_data.image.ai_style,
                ai_size: image_data.image.ai_size,
            }));
            if (response.ok) {
                // window.localStorage.setItem('openai-taskid', response.body.task_id)
                return response.body.task_id

            }
            else {

                if ('body' in response && 'errors' in response.body) {
                    flash(response.body.errors.json.message, 'danger')
                }
                else if (response.status === 401) {
                    flash("Must be logged in and purchase credits to generate AI images", 'danger');
                }
                else {
                    flash('Error with request', 'danger')
                }
                return false

            }

        })();


    }



    const handleAIMessageChange = (event, index, formField) => {

        if (formField === 'message') {
            const updated_resp_data = {
                ai_message: event.target.value
            }
            handleUpdateRespData(mediaName, updated_resp_data);

        }
        else if (formField === 'checkbox') {

            const updated_resp_data = {
                ai_is_hd: event.target.checked
            }

            handleUpdateRespData(mediaName, updated_resp_data);
        }
        else if (formField === 'style') {

            const updated_resp_data =
            {

                ai_style: event.target.value
            }

            handleUpdateRespData(mediaName, updated_resp_data);
        }
        else if (formField === 'size') {

            const updated_resp_data = {

                ai_size: event.target.value
            }

            handleUpdateRespData(mediaName, updated_resp_data);
        }
    }


    useEffect(() => {

        // Listen for window resize events to update the height
        // window.addEventListener('resize', () => handleResize(mediaRef));
        // Clean up the event listener when the component unmounts

        const observer = new ResizeObserver(() => handleResize(mediaRef));
        if (mediaRef.current) {
            addMediaRef(mediaRef);
            observer.observe(mediaRef.current);
            handleResize(mediaRef); // Update the height initially
        }



        if (quillRef.current) {

            const quill = quillRef.current.getEditor();
            quill.format('size', 'large');
            handleResize();

        }
        return () => {
            // window.removeEventListener('resize', handleResize);
            observer && observer.disconnect();
        };
    }, [tabKey]);


    const handleQuillChange = (content, _, __, editor) => {
        let contents = editor.getContents();
        let html = editor.getHTML();
        setValue(contents);


        handleUpdateBaseMessage(mediaName, { html: html });
    };

    const handleImageHover = (data) => {

        setImageHover(data);
        handleImgHoverUpdate(data);


    }

    const handleToggleEditMessage = (index, isImageMessage = true) => {

        if (isImageMessage === true) {
            if (index === false) {

                setActiveEditMessage(false);
            }
            else {


                setActiveEditMessage(index)
            }
        }
        else {
            setActiveEditMessage(false); //TODO d=add togle for editing imgs
            handleToggleImgEditDisplay()

        }
    }

    const mediaSimulationProps = {
        imgWidth: imgWidth, imageHover: imageHover, handleImageHover: handleImageHover, handleImagePositionChange: handleImageMessageListChange, minFontSize: minFontSize, mediaName: mediaName, mediaRef: mediaRef, ref: mediaRef, activeTab: tabKey, handleResize: handleResize, image_data: image_data, height: height, handleUpdateRespData: handleUpdateRespData, value: value, handleQuillChange: handleQuillChange,
    }

    const MEDIA_SIMULATION_CLASS = `bg-white  m-0 px-0 py-sm-0 py-xl-3`
    const MEDIA_SIMULATION_STYLES = { 'textAlign': "-moz-center", 'textAlign': 'center', textAlign: "-webkit-center" }
    // const MEDIA_SIMULATION_STYLES = { 'textAlign': "-moz-center", 'textAlign': 'center', textAlign: "-webkit-center" }





    return (


        <>


            <h1>{image_data['label']}</h1>

            <Col className={`mb-5`} xs={12}>
                {/* <Col className={`mb-5 ${activeEditMessage !== false ? 'hidden' : 'visible'} sidebar editorTransition`} xs={12}> */}

                <Tabs
                    id="controlled-tab-example"
                    activeKey={tabKey}
                    onSelect={(k) => setTabKey(k)}
                    // onSelect={(k) => {
                    //     setTimeout(()=>{
                    //         setTabKey(k);
                    //     },1000)
                    // }}

                    fill
                // mountOnEnter={true}
                // unmountOnExit={false} // Prevent 
                >


                    <Tab eventKey="externalUpload" title="Upload existing design">



                        <Col className={MEDIA_SIMULATION_CLASS} style={MEDIA_SIMULATION_STYLES} xs={12}>

                            <MediaSimulation {...mediaSimulationProps} componentTab="externalUpload" />

                        </Col>

                        <TabEditorBody mediaName={mediaName} image_data={image_data} handleFileInputChange={handleFileInputChange} imgEditDisplay={imgEditDisplay} >
                            <UploadMedia handleFileInputChange={handleFileInputChange} image_data={image_data} mediaName={mediaName} />
                        </TabEditorBody>


                    </Tab>


                    <Tab onClick={() => handleImageHover(false)} eventKey="dragDrop" title="Drag & Drop">

                        <Col className={MEDIA_SIMULATION_CLASS} style={MEDIA_SIMULATION_STYLES} xs={12}>

                            <MediaSimulation {...mediaSimulationProps} componentTab="dragDrop" />

                        </Col>


                        <TabEditorBody mediaName={mediaName} image_data={image_data} handleFileInputChange={handleFileInputChange} imgEditDisplay={imgEditDisplay} >


                            <Collapse in={(activeEditMessage !== false && activeEditMessage !== undefined) ? true : false} timeout={400} >

                                <div className="m-0 p-0" >

                                    {
                                        activeEditMessage !== false && activeEditMessage !== undefined &&
                                        <Col xs={12} lg={12} className={`d-flex justify-content-center mb-5`}>
                                            <Row className="d-flex justify-content-center">
                                                <FontEditorDisplay handleToggleEditMessage={handleToggleEditMessage} handleImageHover={handleImageHover} mediaName={mediaName} handleUpdateRespData={handleUpdateRespData} mediaRef={mediaRef} message_index="top_message" msgData={image_data['image_messages'][activeEditMessage]} index={activeEditMessage} handleImageMessageListChange={handleImageMessageListChange} />
                                                <Col className="my-4" xs={12}>
                                                    <TextAreaField
                                                        placeholder={"Enter your message"}

                                                        onChange={(e) => { handleImageHover(true); handleMessageChange(e, mediaName, activeEditMessage) }}

                                                        formText={'Optional message'}
                                                        value={!image_data['image_messages'][activeEditMessage]["message"] ? '' : image_data['image_messages'][activeEditMessage]["message"]}
                                                        onFocus={(e) => { e.preventDefault(); handleImageHover(true) }}
                                                        onBlur={(e) => { e.preventDefault(); handleImageHover(false) }}
                                                    />
                                                </Col>

                                            </Row>

                                        </Col>
                                    }
                                </div>


                            </Collapse>


                            <Collapse in={(activeEditMessage !== false && activeEditMessage !== undefined) ? false : true} timeout={400} >
                                <div className="m-0 p-0">
                                    {
                                        !activeEditMessage &&

                                        <div>
                                            <CustomMedia handleEmojiUpdate={handleEmojiUpdate} handleToggleEditMessage={handleToggleEditMessage} handleImageHover={handleImageHover} image_data={image_data} mediaName={mediaName} handleImageMessageListChange={handleImageMessageListChange} />
                                            <NewCampaignImageBackground
                                                image_data={image_data}
                                                mediaName={mediaName}

                                                // handleUpdateRespData={handleUpdateRespData}
                                                handleFileInputChange={handleFileInputChange}

                                            />
                                        </div>

                                    }
                                </div>

                            </Collapse>

                        </TabEditorBody>

                    </Tab>



                    <Tab eventKey="splitPost" title="Post a message">

                        <Col className={MEDIA_SIMULATION_CLASS} style={MEDIA_SIMULATION_STYLES} xs={12}>

                            <MediaSimulation {...mediaSimulationProps} componentTab="splitPost" />

                        </Col>

                        <TabEditorBody mediaName={mediaName} image_data={image_data} handleFileInputChange={handleFileInputChange} imgEditDisplay={imgEditDisplay} isPost>


                            <Row className="">
                                <Col className="" xs={12}>

                                    <PostMessageMedia handleFileInputChange={handleFileInputChange} handleEmojiUpdate={handleEmojiUpdate} image_data={image_data} mediaName={mediaName} height={height} minFontSize={minFontSize} handleQuillChange={handleQuillChange} quillRef={quillRef} handleResize={handleResize} imgWidth={imgWidth} />

                                </Col>
                            </Row>
                        </TabEditorBody>

                    </Tab>


                    <Tab eventKey="aiImage" title={<span>Generate background with AI <FcPanorama /></span>}>

                        <Col className={MEDIA_SIMULATION_CLASS} style={MEDIA_SIMULATION_STYLES} xs={12}>

                            <MediaSimulation {...mediaSimulationProps} componentTab="aiImage" />

                        </Col>

                        <TextToImage

                            tabKey={tabKey}
                            handleAIMessageChange={handleAIMessageChange}
                            mediaName={mediaName}
                            handleAIImageSubmit={handleAIImageSubmit}
                            image_data={image_data}
                            handleFileAIInputChange={handleFileAIInputChange}
                            handleFileInputChange={handleFileInputChange}

                        />
                    </Tab>


                </Tabs>


            </Col >





            {/* /* {
                showThumbnail &&
                <div>

                    <Col className="d-flex align-items-center mt-4">
                        <div>
                            <Form.Check // prettier-ignore
                                type={'checkbox'}
                                id={`default-`}
                                label={<strong>Set as thumbnail</strong>}
                                reverse
                                onChange={() => handleThumbnailChange(mediaName)}
                            // checked={image_data.image.thumbnail}
                            />
                        </div>
                    </Col>
                    <hr />
                </div>
            } */ }

        </ >







    )
}

export default CampaignMediaComponent


