import React, { useState, useEffect, useRef } from 'react'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import TextAreaField from './TextAreaField';
import { useApi } from '../contexts/ApiProvider';
import { useFlash } from '../contexts/FlashProvider';
import { useUser } from '../contexts/UserProvider';
import { useImageBuilder } from '../contexts/ImageBuilderProvider';
import { Link } from 'react-router-dom';
import AIImageModal from './AIImageModal';
import AIImageList from './AIImageList';
import NewCampaignImageBackground from './NewCampaignImageBackground';





//todod clean up rendering logic and use the context variable to track the current balance. 



function TextToImage({ tabKey, mediaName, image_data, handleFileAIInputChange, formHandlers, imageHandlers,handleFileInputChange,handleAIMessageChange }) {
    // const [showAccountBalanceModal, setShowAccountBalanceModal] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const { respData, handleUpdateAIImages, handleUpdateRespData } = useImageBuilder()
    const api = useApi()
    const flash = useFlash();
    const user = useUser()
    const [accountBalance, setAccountBalance] = useState()
    // const [currentBalance, setCurrentBalance] = useState(0)

    const descriptionRef = useRef()
    const [formErrors, setFormErrors] = useState({ "message": false })
    const [noCredits, setNoCredits] = useState(false);


    const [show, setShow] = useState(false)
    const [modalData, setModalData] = useState()

    // const handleShowAccountBalanceModal = (data) => {
    //     setShowAccountBalanceModal(data)
    // }

    const handleShowModal = (isShow, data = false) => {
        setShow(isShow);

        data && setModalData(data);
    }


    useEffect(() => {
        if (accountBalance) {
            if (accountBalance['paid_quantity'] === 0 || accountBalance['current_quantity'] === 0) {
                setNoCredits(true)
            }
        }

    }, [respData, accountBalance]);






    const handleAIImageSubmit = () => {

        return (async () => {
            const response = await api.post(`/new_campaign/new-ai-image`, JSON.stringify({
                ai_message: image_data.image.ai_message,
                ai_is_hd: image_data.image.ai_is_hd,
                ai_quality: image_data.image.ai_is_hd,
                ai_style: image_data.image.ai_style,
                ai_size: image_data.image.ai_size,
            }));
            if (response.ok) {
                // window.localStorage.setItem('openai-taskid', response.body.task_id)
                return response.body.task_id

            }
            else {

                if ('body' in response && 'errors' in response.body) {
                    flash(response.body.errors.json.message, 'danger')
                    setFormErrors(response.body.errors.json)
                }
                else if (response.status === 401) {
                    flash("Must be logged in and purchase credits to generate AI images", 'danger');
                    setFormErrors({ 'message': 'Must be logged in and purchase credits to generating AI images' })
                }
                else {
                    flash('Error with request', 'danger')
                    setFormErrors({ 'message': 'Error with request' })
                }
                return false

            }

        })();


    }


    const handleSubmit = async () => {
        setIsProcessing(true);
        setFormErrors()
        // setFormErrors({ message: false })
        console.log(handleAIImageSubmit)
        try {
            const result = await handleAIImageSubmit();
            console.log("submit results", result);
            result ? handleTaskTimer(result) : setIsProcessing(false)

        } catch (error) {
            console.log(error)
            setIsProcessing(false)
        }


    }

    useEffect(() => {
        if (image_data && 'ai_message' in image_data && image_data['image']['ai_message'].length === 0) {
            setIsDisabled(true)
        }
        else {

            setIsDisabled(false)
        }

    }, [image_data])


    const inc = useRef(0);
    const MAX_REQUESTS = 20;
    const timeoutId = useRef(null);

    const handleTaskTimer = (task_id) => {

        let fetchData = (async () => {
            const response = await api.get(`/new_campaign/get-openai-image/${task_id}`);
            if (response.ok) {

                if (response.body.success === true) {
                    if ('errors' in response.body.data) {
                        flash(response.body.data.errors.json.message, 'danger');

                        setFormErrors(response.body.data.errors.json)
                        // setCurrentBalance(response.body.account_balance)
                        setIsProcessing(false)

                        return;
                    }
                    console.log('success', response.body)

                    // setAIImages(response.body.urls)
                    handleUpdateAIImages(response.body.data, response.body.account_balance)
                    // handleUpdateAIImages(response.body.urls)
                    // setCurrentBalance(response.body.account_balance)
                    setIsProcessing(false)

                    return

                }
                else if (!response.body.success) {
                    inc.current += 1;
                    if (inc.current < MAX_REQUESTS) {

                        timeoutId.current = setTimeout(fetchData, 3000);
                    }


                }
                else {
                    inc.current += 1;

                    if (inc.current < MAX_REQUESTS) {

                        timeoutId.current = setTimeout(fetchData, 3000);
                    }


                }
            }
            else {

                console.log(response, 'hi')
                if ('errors' in response.body) {
                    flash(response.body.errors.json.message, 'danger');

                    setFormErrors(response.body.errors.json)


                }
                else if (response.status === 401) {
                    fetchData()
                    flash("Must be logged in and purchase credits to generating AI images", 'danger');
                    setFormErrors({ 'message': 'Must be logged in and purchase credits to generating AI images' })

                }
                else {
                    flash("Error with request", 'danger');
                    setFormErrors({ 'message': 'Error with request' })
                }

                // alert('how')
                // console.log(response);
                // alert('error')
                // inc.current += 1
                setIsProcessing(false)
                clearInterval(timeoutId.current)
                return
                // showModal(false);
            }

        });
        fetchData();



    }


    // #TODO: MAKE SURE ONLY LOGGED IN USERS CAN REQUEST AI PHOTOS. SHOW MESG IF NOT LOGGED IN


    useEffect(() => {
        tabKey === "aiImage" && descriptionRef.current && descriptionRef.current.focus()
    }, [tabKey])


    useEffect(() => {
        if (respData) {
            setAccountBalance(respData['account_balance'])
        }
        return () => {
            if (timeoutId.current) {
                clearInterval(timeoutId.current)
                timeoutId.current = null
            }
        }
    }, [respData])

    return (

        <Container fluid className="mb-5">
            <AIImageModal
                show={show}
                hide={() => handleShowModal(false)}
                image={modalData}

            />

            <Form.Group controlId="aiImage" className="mt-2">
                <Form.Group style={{ maxWidth: '1000px' }} controlId="aiImageText">
                    <Form.Label ><strong>Create an image using AI from a text prompt</strong></Form.Label>
                    <TextAreaField fieldRef={descriptionRef} placeholder="Describe your image in as much detail as possible in under 4,000 charachters" onChange={(e) => handleAIMessageChange(e, mediaName, 'message')} />


                </Form.Group>
                <Stack style={{ display: 'flex', alignItems: 'baseline' }} direction="horizontal" gap={4}>

                    <Row>

                        <Col xs={12} lg={6} className="mb-3">
                            <Row>

                                <Col xs={6} lg={12}>
                                    <Form.Label><strong><u>HD Image</u></strong></Form.Label>
                                    <Form.Check // prettier-ignore
                                        type={'checkbox'}
                                        id={`is-hd`}
                                        label={<strong>Yes</strong>}
                                        onChange={(e) => handleAIMessageChange(e, mediaName, 'checkbox')}
                                        checked={image_data['image']['ai_is_hd'] ? true : false}
                                    />
                                    <Form.Text>HD images take longer to process</Form.Text>
                                </Col>
                                <Col xs={6} lg={12}>
                                    <div>

                                        <Form.Label><strong><u>Style</u></strong></Form.Label>
                                        <div>
                                            <Form.Check // prettier-ignore

                                                inline
                                                type={'radio'}
                                                id={`ai-style-vivid`}
                                                value={'vivid'}
                                                label={<strong>Vivid theme</strong>}
                                                onChange={(e) => handleAIMessageChange(e, mediaName, 'style')}
                                                checked={image_data['image']['ai_style'] === 'vivid'}
                                            />
                                            <Form.Check // prettier-ignore
                                                inline
                                                type={'radio'}
                                                id={`ai-style-natural`}
                                                value={'natural'}
                                                label={<strong>Natural theme</strong>}
                                                onChange={(e) => handleAIMessageChange(e, mediaName, 'style')}
                                                checked={image_data['image']['ai_style'] === 'natural'}
                                            />
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </Col>

                        <Col xs={12} lg={6} className="mb-3">
                            <Form.Label><strong><u>Size</u></strong></Form.Label>

                            <div className="d-flex ">

                                <div>
                                    <Form.Check // prettier-ignore

                                        inline
                                        type={'radio'}
                                        id={`ai-size_1024x1024`}
                                        value={'1024x1024'}
                                        label={<strong>1024 x 1024</strong>}
                                        onChange={(e) => handleAIMessageChange(e, mediaName, 'size')}
                                        checked={image_data['image']['ai_size'] === '1024x1024'}
                                    />
                                    <div style={{
                                        maxWidth: '150px',
                                        maxHeight: '150px',
                                        aspectRatio: 1,
                                        border: '2px solid grey',

                                    }} />
                                </div>

                                <div className="mx-3">
                                    <Form.Check // prettier-ignore
                                        inline
                                        type={'radio'}
                                        id={`ai-size_1792x1024`}
                                        value={'1792x1024'}
                                        label={<strong>1792 x 1024</strong>}
                                        onChange={(e) => handleAIMessageChange(e, mediaName, 'size')}
                                        checked={image_data['image']['ai_size'] === '1792x1024'}
                                    />
                                    <div style={{
                                        maxWidth: '150px',
                                        maxHeight: '150px',
                                        aspectRatio: 1792 / 1024,
                                        border: '2px solid grey',

                                    }} />
                                </div>
                                <div>
                                    <Form.Check // prettier-ignore
                                        inline
                                        type={'radio'}
                                        id={`ai-size_1024x1792`}
                                        value={'1024x1792'}
                                        label={<strong>1024 x 1792</strong>}
                                        onChange={(e) => handleAIMessageChange(e, mediaName, 'size')}
                                        checked={image_data['image']['ai_size'] === '1024x1792'}
                                    />
                                    <div style={{
                                        maxWidth: '150px',
                                        maxHeight: '150px',
                                        aspectRatio: 1024 / 1792,
                                        border: '2px solid grey',

                                    }} />
                                </div>
                            </div>


                        </Col>

                    </Row>






                </Stack>
                {/* <Stack direction="horizontal" gap={4}>
                    <div className="d-inline-flex">

                        <Form.Group className="mb-3" controlId="aiImageHD">
                            <Form.Check // prettier-ignore
                                type={'checkbox'}
                                id={`is-hd`}
                                label={<strong>HD Image</strong>}
                                onChange={(e) => handleAIMessageChange(e, mediaName, 'checkbox')}
                                checked={image_data['image']['ai_is_hd']}
                            />
                            <Form.Text>HD images take longer to process</Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="aiImageHD">
                            <Form.Check // prettier-ignore
                                inline
                                type={'radio'}
                                id={`ai-style-vivid`}
                                value={'vivid'}
                                label={<strong>Vivid theme</strong>}
                                onChange={(e) => handleAIMessageChange(e, mediaName, 'radio')}
                                checked={image_data['image']['ai_style'] === 'vivid'}
                            />
                            <Form.Check // prettier-ignore
                                inline
                                type={'radio'}
                                id={`ai-style-natural`}
                                value={'natural'}
                                label={<strong>Natural theme</strong>}
                                onChange={(e) => handleAIMessageChange(e, mediaName, 'radio')}
                                checked={image_data['image']['ai_style'] === 'natural'}
                            />

                        </Form.Group>
                    </div>
                    <div className="d-inline-flex">


                        <Form.Group className="mb-3" controlId="aiImageSize">
                            <Form.Check // prettier-ignore
                                inline
                                type={'radio'}
                                id={`ai-style-vivid`}
                                value={'vivid'}
                                label={<strong>1024x1024</strong>}
                                onChange={(e) => handleAIMessageChange(e, mediaName, 'radio')}
                                checked={image_data['image']['ai_style'] === 'vivid'}
                            />
                            <Form.Check // prettier-ignore
                                inline
                                type={'radio'}
                                id={`ai-style-natural`}
                                value={'natural'}
                                label={<strong>1024x1026</strong>}
                                onChange={(e) => handleAIMessageChange(e, mediaName, 'radio')}
                                checked={image_data['image']['ai_style'] === 'natural'}
                            />
                            <Form.Check // prettier-ignore
                                inline
                                type={'radio'}
                                id={`ai-style-natural`}
                                value={'natural'}
                                label={<strong>1024x1028</strong>}
                                onChange={(e) => handleAIMessageChange(e, mediaName, 'radio')}
                                checked={image_data['image']['ai_style'] === 'natural'}
                            />

                        </Form.Group>
                    </div>
                </Stack> */}
                <div>
                    <div className="d-inline-grid">
                        <Button className="rounded-pill" disabled={isDisabled || isProcessing || (user && !user.user) || (noCredits === true)} onClick={() => handleSubmit()}>{
                            isProcessing ?
                                <span>
                                    Processing
                                    {/* <Spinner size={16} /> */}
                                </span>
                                :
                                "Create image"
                        }</Button>


                        {
                            formErrors && 'message' in formErrors && formErrors['message'].length > 0 && <Form.Text className="text-danger">{formErrors['message']} </Form.Text>
                        }


                        <div className="mt-3">
                            {
                                user && !user.user ?

                                    < div > <Link to={{ pathname: '/login' }}>Login</Link> or <Link to={{ pathname: '/register' }}>Sign Up</Link> to purchase credits</div>
                                    :
                                    (respData && 'account_balance' in respData) ?
                                        respData['account_balance']['paid_quantity'] === 0 ?

                                            <div>You have no image credits. Purchase more <Link to={{ pathname: '/account-balance' }}>here</Link></div>
                                            :

                                            <Form.Text>
                                                <div>You have {respData['account_balance']['current_quantity']} out of {respData['account_balance']['paid_quantity']}  image credits left. Purchase more credits <Link to={{ pathname: '/account-balance' }}>here</Link></div>
                                            </Form.Text>
                                        :
                                        <div></div>

                            }
                            {/* {
                                currentBalance &&
                                    currentBalance.paid_quantity === 0 ?
                                    user && !user.user ?
                                        'Create an account to purchase credits'
                                        :
                                        <div>'You have no image credits. Purchase more <Link to={{ pathname: '/account-balance' }}>here</Link></div>
                                    :
                                    `You have ${currentBalance['current_quantity']} out of ${currentBalance['paid_quantity']}  image credits left`
                            } */}
                        </div>
                    </div>
                </div >
            </Form.Group >
            {
                image_data["image"]['url'] && image_data['image']['url'] !== null &&
                < NewCampaignImageBackground
                    image_data={image_data}
                    mediaName={mediaName}
                    imageHandlers={imageHandlers}
                    handleUpdateRespData={handleUpdateRespData}
                    handleFileInputChange={handleFileInputChange}
                    message_index="base_message"
                />
            }


            {
                respData['ai_images'] && 'images' in respData['ai_images'] && respData['ai_images'].images.length > 0 &&
                <div>
                    <h3><strong>Your images</strong></h3>
                    <p className="fs-5">Scroll to the right to view more</p>
                    <div style={{
                        "overflowX": "auto",
                        "whiteSpace": "nowrap",
                    }} className="mb-3">
                        <Row >




                            <AIImageList
                                inline
                                mediaName={mediaName}
                                newMessage={image_data['image']}
                                aiImages={respData['ai_images']}
                                noRequest
                                isLoading={isProcessing}
                                handleFileAIInputChange={handleFileAIInputChange}
                            />

                        </Row>
                    </div>
                </div>


            }


        </Container>

    )
}

export default TextToImage
