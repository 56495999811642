import React, { useRef, useEffect } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FaRegSave } from "react-icons/fa";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import { FaGear } from "react-icons/fa6";
import EmojiPicker from './EmojiPicker';
import { useImageBuilder } from '../contexts/ImageBuilderProvider';
import { FaFaceSmile } from "react-icons/fa6";
import ReactQuill, { Quill } from 'react-quill';
import NewCampaignImageBackground from './NewCampaignImageBackground';
import 'react-quill/dist/quill.snow.css'; // Import styles
import '../styles/QuillToolbar.css'
import Popover from 'react-bootstrap/Popover';
import { FaGear } from "react-icons/fa6";
import useSaveProgress from '../utils/useSaveProgress';



function PostMessageMedia({ image_data, mediaName, handleEmojiUpdate, height, minFontSize, handleQuillChange, quillRef, imgWidth, handleFileInputChange }) {



    const { handleUpdateBaseMessage, handleToggleImgEditDisplay } = useImageBuilder()
    const { flashMessage, loading, saveProgress } = useSaveProgress()
    const handleSaveProgress = () => {
        saveProgress();
    }


    const popover2 = useRef()
    const popover = <Popover id={`popover-${12}`}>
        <Popover.Body>Background settings</Popover.Body>
    </Popover>    





    const Font = Quill.import('formats/font');
    Font.whitelist = ['sans-serif', 'serif', 'monospace', 'courier-new', 'times-new-roman', 'georgia', 'tahoma', 'verdana', 'trebuchet-ms'];

    const Size = ReactQuill.Quill.import('formats/size');
    const fontSizeArray = [];
    for (let i = 0.5; i <= 5; i += 0.25) {
        fontSizeArray.push(`${parseFloat(i.toFixed(2))}em`);
    }
    Size.whitelist = ['small', 'medium', 'large', 'xlarge', 'xxlarge', 'huge']; // Define the sizes you want

    Quill.register(Size, true);

    Quill.register(Font, true);




    const modules = {
        toolbar: {
            container: [
                [{ 'font': Font.whitelist }],
                [{ 'size': Size.whitelist }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'align': [] }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['clean']
            ],
        }
    };

    const formats = [
        'font', 'size', 'bold', 'italic', 'underline', 'strike',
        'color', 'background', 'align', 'list', 'bullet', 'clean'
    ];

    const textShadow = `
    ${image_data['base_message']['outline_width']}em 0px ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}, 
    0px ${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}, 
    -${image_data['base_message']['outline_width']}em 0px ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}, 
    0px -${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}, 
    -${image_data['base_message']['outline_width']}em -${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}, 
    -${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}, 
    ${image_data['base_message']['outline_width']}em -${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}, 
    ${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}
`



    useEffect(() => {
        // Ensure the editor is fully initialized before setting HTML content
        // var timeout;

        if (quillRef.current) {
            var editor = quillRef.current.getEditor(); // Get the Quill editor instance

            if (editor) {




                editor.format('size', 'medium'); // Set the default font size to medium

                editor.clipboard.dangerouslyPasteHTML(image_data['base_message']['html'])



            }
        }




    }, []); // Ensure effect runs only once when editor is ready


    const quillEditorStyle = image_data['image']['is_gradient'] === true ?

        {
            background: `linear-gradient(${image_data['image']['gradient_deg']}deg, ${image_data['image']['gradient_color_1']}, ${image_data['image']['gradient_color_2']})`,
            borderTopRightRadius: ".5em",
            borderTopLeftRadius: "1em",
            borderLeft: '.75px solid #ccc',
            borderRight: '.75px solid #ccc',
            // height: height + 60,
            height: height + 60,
            minHeight: '250px',
            color: `${image_data['base_message']['color']}`,
            width: imgWidth,
        }
        :
        {
            backgroundColor: image_data['image']['background_color'],
            borderTopRightRadius: ".5em",
            borderTopLeftRadius: "1em",
            borderLeft: '.75px solid #ccc',
            borderRight: '.75px solid #ccc',
            // height: height + 60,
            height: height + 60,
            minHeight: '250px',
            color: `${image_data['base_message']['color']}`,
            width: imgWidth,
        }

    const quillParentStyle = image_data['image']['is_gradient'] === true ?
        {
            fontFamily: `${image_data['base_message']['font_family']}`,
            textShadow: textShadow,
            fontSize: minFontSize + 'px',
            position: 'relative',
            borderTopRightRadius: '.5rem',
            borderTopLeftRadius: '.5rem',
            // height: height,
            background: `linear-gradient(${image_data['image']['gradient_deg']}deg, ${image_data['image']['gradient_color_1']}, ${image_data['image']['gradient_color_2']})`,
            color: `${image_data['base_message']['color']}`,
        }
        :
        {
            fontFamily: `${image_data['base_message']['font_family']}`,
            textShadow: textShadow,
            fontSize: minFontSize + 'px',
            position: 'relative',
            borderTopRightRadius: '.5rem',
            borderTopLeftRadius: '.5rem',
            backgroundColor: !image_data['image']['background_color'] ? 'white' : image_data['image']['background_color'],
            color: `${image_data['base_message']['color']}`,
        }

    return (

        <Form>
            <Row className="bg-white m-0 p-0 d-flex justify-content-center">
                <Col xs={12} className="d-flex justify-content-center">


                    <Form.Group className="mb-5 ">
                        <Form.Label
                            className="m-3 text-dark"

                        ><strong>Your CrowdPost message</strong></Form.Label>

                        <div

                            style={quillParentStyle}>


                            <ReactQuill
                                // value={value}
                                // tabIndex={-1} // Control focusability
                                onChange={handleQuillChange}
                                ref={quillRef}
                                modules={modules}
                                formats={formats}
                                style={quillEditorStyle}
                            />




                            <div className="d-flex justify-content-start bg-white"
                                style={{
                                    borderBottomRightRadius: '.5rem',
                                    borderBottomLeftRadius: '.5rem',
                                    borderTop: 'rgb(230, 221, 221) solid 1px',
                                    position: "absolute",
                                    top: "100%", /* 100% from the top of the parent element */
                                    left: "0",
                                    width: "100%",
                                }}
                            >


                                <OverlayTrigger rootClose={true} trigger={['focus', 'hover']} placement="left" overlay={popover}>
                                    <Button onClick={() => handleToggleImgEditDisplay(mediaName)} variant="outline-dark"><FaGear size={24} /></Button>
                                </OverlayTrigger>


                                <Button className="mx-4" onClick={() => handleSaveProgress()} variant="outline-dark">
                                    {
                                        flashMessage ?
                                            <span className={` fw-bold text-${flashMessage['color']}`}> <FaRegSave size={22} className="mx-1" /> {flashMessage['message']} </span>
                                            :
                                            loading ?
                                                <span><FaRegSave size={22} className="mx-1" /> Saving...</span>
                                                :
                                                <span><FaRegSave size={22} className="mx-1" /> Save</span>

                                    }
                                </Button>




                                <OverlayTrigger rootClose={true} trigger="click" placement="top" overlay={<div>
                                    <EmojiPicker ref={popover2} handleEmojiUpdate={handleEmojiUpdate} index={'base'} mediaName={mediaName} />
                                </div>}>
                                    <Button variant="outline-dark"><FaFaceSmile size={24} /></Button>
                                </OverlayTrigger>
                            </div>


                        </div>







                    </Form.Group>


                </Col>




            </Row>
            <Row >
                <Col className="d-flex align-items-center my-4" xs={"12"}>
                    <Form.Check type={"checkbox"} id="include_name">

                        <Form.Check onChange={(e) => handleUpdateBaseMessage(mediaName, { include_name: e.target.checked })} checked={image_data.base_message['include_name']} type={"checkbox"} />
                        <Form.Check.Label>{<strong>{"Include name and username"}</strong>}</Form.Check.Label>
                    </Form.Check>
                </Col>
                <NewCampaignImageBackground
                    image_data={image_data}
                    mediaName={mediaName}

                    handleFileInputChange={handleFileInputChange}


                />
            </Row>


        </Form >

    )
}

export default PostMessageMedia
