import { Link } from 'react-router-dom'

export const timeframes = [
    { start: 0, end: 4, id: 0, label: "Starting a campaign:" },
    { start: 4, end: 11, id: 1, label: "Select a billboard or mobile campaign type." },
    { start: 11, end: 27, id: 2, label: "Select a location for your campaign." },
    { start: 27, end: 43, id: 3, label: "Create or upload media for your campaign" },
    { start: 43, end: 48, id: 4, label: "Set a fundraising period for your campaign" },
    { start: 48, end: 53, id: 5, label: "Create a title and optional description." },
    { start: 53, end: 65, id: 6, label: "Agree to our terms of service & post." },
    // Add more timeframes as needed
];

export const creatingMedia = [
    { start: 0, end: 4, id: 0, label: "To create media you can:", ind: true, },
    { start: 4, end: 11, id: 1, label: "Upload your own design" },
    { start: 11, end: 27, id: 2, label: "Post a message" },
    { start: 27, end: 43, id: 3, label: "Use our Drag & Drop editor" },
    { start: 43, end: 48, id: 4, label: "Generate and use AI images in your design (paid feture)" },
    { start: 48, end: 53, id: 5, label: "Generate free mockups of your design on your media type" },
    // Add more timeframes as needed
];

export const payment_video_timeframes = [
    { start: 0, end: 3, id: 0, label: "Contributing to a campaign:" },
    { start: 4, end: 6, id: 1, label: "Enter an amount to contribute" },
    { start: 6, end: 10, id: 2, label: "Enter the name your contribution will appear as on the campaign page or contribute anonymously" },
    { start: 10, end: 15, id: 3, label: "Add an optional message to your contribution" },
    { start: 15, end: 18, id: 4, label: "Enter an email to receive payment confirmations and campaign updates." },
    { start: 18, end: 32, id: 5, label: "Enter payment details" },
    { start: 32, end: 38, id: 6, label: "Contribute" },
    // Add more timeframes as needed
];

export const ai_image_video = [
    { start: 0, end: 3, id: 0, label: "Creating AI Images:" },
    {
        start: 4, end: 6, id: 1, label: <span>Create an account and purchase image credits. Pricing info can be found     <Link to={{ pathname: "/ai-images" }} className="" >here </Link></span>
    },
    { start: 6, end: 10, id: 2, label: "Enter a text prompt up to 4,000 charachters long." },
    { start: 10, end: 15, id: 3, label: "Set your image settings (size, style, quality)" },
    { start: 15, end: 18, id: 4, label: "Your image is sized for the media type you are creating" },
    { start: 18, end: 32, id: 5, label: "Use the image when creating your media campaigns" },

    // Add more timeframes as needed
];

export const favoriteFollowVideo = [
    { start: 6, end: 10, id: 2, label: "Favorite campaigns" },
    { start: 10, end: 15, id: 3, label: "Follow users" },
    { start: 15, end: 18, id: 4, label: "Create a personalized campaign feed of the users you follow and the campaigns you favorite" },
    { start: 18, end: 32, id: 5, label: "Create an account to create your campaign feed" },
    { start: 18, end: 32, id: 5, label: "CrowdPost is where social and outdoor media meet." },

    // Add more timeframes as needed
];
