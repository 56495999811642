import React from 'react'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import EmailShare from './EmailShare';
import FacebookShare from './FacebookShare';
import TwitterShare from './TwitterShare';
import CopyCampaign from './CopyCampaign';
import SocialMediaIcon from './SocialMediaIcon';
import NextdoorShare from './NextdoorShare';
// import NativeShareButton from './NativeShareButton';

function ShareComponents({ campaignId, amount }) {


    // const link = campaignId ? `${process.env.REACT_APP_BASE_API_URL}/campaign/${campaignId}` : window.location.href



    return (

        <Row>
            {campaignId &&
                <Col className="d-flex justify-content-evenly" xs={12}>
                    <TwitterShare campaignId={campaignId} />
                    <EmailShare campaignId={campaignId} />
                    <FacebookShare campaignId={campaignId} />
                    <NextdoorShare campaignId={campaignId} />
                    <CopyCampaign campaignId={campaignId} />
                    <CopyCampaign campaignId={campaignId} blurb/>
                    {/* <NativeShareButton campaignId={campaignId}  /> */}
                </Col>
            }
        </Row>



    )
}

export default ShareComponents
