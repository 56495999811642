import { useState, useRef,useEffect } from 'react';
// import MicroblogApiClient from '../MicroblogApiClient';

import { useApi } from "../contexts/ApiProvider";
import { useFlash } from '../contexts/FlashProvider';
import { useImageBuilder } from '../contexts/ImageBuilderProvider';


export default function useSaveProgress() {
    const flash = useFlash();
    const { respData } = useImageBuilder()
    const [loading, setLoading] = useState(false);
    const api = useApi();
    const [flashMessage, setFlashMessage] = useState()

    const timeoutIds = useRef([]); // Use ref to store the list of timeout IDs

    useEffect(() => {
        
        return () => {
            timeoutIds.current.forEach((timeoutId) => clearTimeout(timeoutId));
            timeoutIds.current = []; // Clear the list
        }
    }, [])

    const saveProgress = () => {


        setLoading(true);
        setFlashMessage();
        (async () => {
            const response = await api.post(`/new_campaign/save-progress`, respData);
            if (response.ok) {
                setLoading(false);
                if (response.body.success === true) {
                    setFlashMessage({ 'color': 'success', 'message': 'Your progress was saved' })
                    let t = setTimeout(() => {
                        setFlashMessage()
                    }, 7000);
                    timeoutIds.current.push(t)
                    flash("Progress saved", 'success')

                    return response.body
                }
            }
            else {
                setLoading(false);


                if ('body' in response && 'errors' in response.body) {

                    flash(response.body.errors.json.message, 'danger')
                    setFlashMessage({ 'color': 'danger', 'message': response.body.errors.json.message })
                }
                else {

                    flash('Error with request', 'danger')
                    setFlashMessage({ 'color': 'danger', 'message': 'Error with request' })
                }
                let t = setTimeout(() => {
                    setFlashMessage()
                }, 7000);
                timeoutIds.current.push(t)


                return response.body

            }

        })();
    }









    return { saveProgress, loading, flashMessage };
}