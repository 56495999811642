import React from 'react'
import { nextdoorIcon } from '../utils/staticUrls';
import SocialMediaIcon from './SocialMediaIcon';

function NextdoorShare({ campaignId }) {
    let u = `${process.env.REACT_APP_BASE_API_URL}/campaign/${campaignId}`
    let body = `Contribute to this media campaign, if not enough is raised, nobody is charged.${u}.`
    var shareUrl = `https://nextdoor.com/sharekit/?source=crowdpost&body=${encodeURIComponent(body)}`

    return (


        <div>


            <a href={shareUrl} target="_blank" rel="noopener noreferrer" aria-label="">

                <SocialMediaIcon iconPath={nextdoorIcon} size={50} />
            </a>
        </div>




    )
}

export default NextdoorShare
