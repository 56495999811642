import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import CampaignMap from './CampaignMap';
import ShowMapButton from './ShowMapButton';
import { useLocation } from 'react-router-dom'
import PaymentSuccessModal from './PaymentSuccessModal';





function Campaigns({ routeName }) {
  const [campaigns, setCampaigns] = useState();

  const [isProcessing, setIsProcessing] = useState(false);
  const [defaultLoc, setDefaultLoc] = useState();

  const [bbox, setBbox] = useState(null) //contains markers for the map. 


  const [showMap, setShowMap] = useState(false)

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const modal = queryParams.get("modal")
  const campaignId = queryParams.get("campaignId")
  const amount = queryParams.get("amount")


  const [showModal, setShowModal] = useState(modal && modal === 'true' ? true : false);

  const handleCloseModal = () => {
    setShowModal(false);
  };


  const toggleMap = () => showMap ? setShowMap(false) : setShowMap(true)




  const changeCampaigns = (data) => setCampaigns(() => data)





  return (

    <Row className="" style={isProcessing ?
      {
        pointerEvents: "none",

      }
      :
      null
    }>

      {
        showModal && <PaymentSuccessModal showModal={showModal} handleCloseModal={handleCloseModal} campaignId={campaignId} amount={amount} />
      }
      <>
        {campaigns === null ?
          <div className='mt-5 text-center'>
            <p className="text-danger"><strong>We're sorry there was an error returning your search. Please try again later</strong></p>
          </div>
          :
          <>

            <CampaignMap showMap={showMap} routeName={routeName} bbox={bbox} setBbox={setBbox} setCampaigns={setCampaigns} changeCampaigns={changeCampaigns} defaultLoc={defaultLoc} campaigns={campaigns} isLoading={isProcessing} setIsLoading={setIsProcessing} />
          </>
        }
      </>


      <ShowMapButton toggleMap={toggleMap} showMap={showMap} />


    </Row >

  )
}

export default Campaigns