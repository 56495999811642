import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import CampaignImageList from './CampaignImageList';
import { Link } from 'react-router-dom';
import GetPrice from './GetPrice';
import DonorSummary from './DonorSummary';
import CampaignUpdates from './CampaignUpdates';
import CampaignOrganizer from './CampaignOrganizer';
import CampaignComments from './CampaignComments';
import Favorite from './Favorite'
import PricingComponent from './PricingComponent';
import DescriptionComponent from './DescriptionComponent';
import CampaignPageMap from './CampaignPageMap';
import TimeLeft from './TimeLeft';



function CampaignPageDesktopStack({ campaign, comments, modalImages, handleShowAll, showAll, user, setShowPostUpdateModal, setShowContactModal, showPostUpdateModal, campaignUpdates, handleDeleteMessage, getUpdates, rowStyle, getComments, donorSummary, cardStyle, handleCampaignUpdates, handleShowDonationProtectedModal, handleDescriptionUpdate, handleWhatCanBePostedModal, handleUpdateShareModal, showShareModal }) {




    return (
        <>
        
            <Col className="d-none d-lg-block" xxl={8} lg={7}>
                <Stack className="d-lg-block" gap={3}>
                    <h3 className="fw-bolder">{campaign.description.title}</h3>




                    {
                        campaign && campaign.images &&
                        <CampaignImageList modalImages={modalImages} imageType={'image'} handleShowAll={() => handleShowAll('image')} showAll={showAll} status={campaign.status} images={campaign.images} isLiked={campaign.is_liked} campaign_id={campaign.id} />
                    }

                    <div className="d-flex my-3">
                        <div>
                            <Favorite campaignId={campaign.id} isLiked={campaign.is_liked} />
                        </div>

                    </div>




                    {
                        (campaign.description.images.length === 0 && !campaign.description.youtube_url) ?

                            user && user.username === campaign.username
                                ?
                                <div>
                                    <hr />
                                    <DescriptionComponent
                                        description={campaign.description}
                                        username={campaign.username}
                                        campaignId={campaign.id}
                                        handleDescriptionUpdate={handleDescriptionUpdate}
                                    />
                                    <hr />
                                </div>
                                :
                                <div></div>
                            :
                            <div>
                                <hr />
                                <DescriptionComponent
                                    description={campaign.description}
                                    username={campaign.username}
                                    campaignId={campaign.id}
                                    handleDescriptionUpdate={handleDescriptionUpdate}
                                />
                                <hr />
                            </div>

                    }



                    <h3 ><strong>Campaign Location</strong></h3>

                    <CampaignPageMap location={campaign.location} campaign_mode={campaign.campaign_mode} />
                    {/* <CampaignLocationMap location={campaign.location} campaign_mode={campaign.campaign_mode} /> */}


                    <CampaignOrganizer
                        campaign={campaign}
                        user={user}
                        setShowContactModal={setShowContactModal}
                    />

                    <CampaignUpdates
                        user={user}
                        campaignUpdates={campaignUpdates}
                        setShowPostUpdateModal={setShowPostUpdateModal}
                        handleDeleteMessage={handleDeleteMessage}
                        getUpdates={getUpdates}
                        campaignId={campaign.id}
                        handleCampaignUpdates={handleCampaignUpdates}
                        showPostUpdateModal={showPostUpdateModal}
                        username={campaign.username}

                    />

                    <CampaignComments
                        comments={comments}
                        getComments={getComments}
                        rowStyle={rowStyle}
                    />
                </Stack>
            </Col>

            <Col className="mt-4 d-none d-lg-block" style={{ position: 'sticky', top: 0, }} lg={5} xxl={4}>

                <Card className="shadow-lg rounded-lg p-2 bg-dark" style={cardStyle}>
                    <Card.Body>
                        {
                            campaign && campaign.denial_message &&
                            <>
                                <Col>
                                    <div>
                                        <h4 className="fw-bolder text-danger">Reason for campaign denial</h4>
                                        {campaign.denial_message}

                                    </div>
                                </Col>
                                <hr />
                            </>
                        }
                        <Col xs={12}>

                            <div className="d-flex align-content-center">

                                {
                                    campaign.username !== 'anonymous' &&
                                    <div className="mx-3">
                                        <Image height={50} width={50} src={campaign.avatar_url} roundedCircle />
                                    </div>
                                }
                                <div className="">

                                    {
                                        campaign.username === 'anonymous' ?
                                            <div>
                                                <h5 className="">
                                                    <strong>Posted by:</strong> anonymous
                                                </h5>

                                            </div>
                                            :
                                            <div>
                                                <h5>
                                                    <strong>Posted by: </strong>
                                                    <Link
                                                        to={{ pathname: '/user/' + campaign.username }}
                                                    >
                                                        {campaign.username}
                                                    </Link>

                                                </h5>
                                            </div>
                                    }
                                    <div className="d-flex justify-content-between">


                                        {/* 
                                        <h5 className="my-2 d-sm-block d-lg-none"><GetPrice priceInCents={campaign.current_amount} /> raised with {campaign.num_payments} payments

                                        </h5> */}


                                        <div><strong>Campaign type:</strong> <span className="text-muted">{campaign.campaign_mode}</span></div>
                                        <strong className="mx-2">|</strong>
                                        <div className="d-inline-flex "><strong>Status:</strong><span className="text-muted" style={{ paddingLeft: "5px" }}>{campaign && campaign.status_label}</span></div>
                                    </div>


                                </div>
                            </div>

                        </Col>

                        {/* <hr />
                        {
                            campaign.url &&
                            <ShareComponents campaignId={campaign.url} />
                        } */}


                    <hr />

                    <div className="mb-2">
                        <GetPrice priceInCents={campaign.current_amount} >raised
                            with <strong>{campaign.num_payments} {campaign.num_payments === 1 ? 'payment' : 'payments'}</strong>
                            <span>

                                <TimeLeft endDate={campaign.start_date} isoDate={campaign.start_date} endText={<span className=""> left to contribute</span>} />
                            </span>
                        </GetPrice>
                    </div>

                    {'adjusted_amount' in campaign &&
                        <div className="mb-2 d-inline-flex">
                            <span className="text-secondary fw-bold">Final charge: </span>
                            <GetPrice priceInCents={campaign.adjusted_amount} >
                                with <strong>{campaign.num_payments} {campaign.num_payments === 1 ? 'payment' : 'payments'}</strong>
                            </GetPrice>
                        </div>
                    }

                    {campaign.status === 'active' && campaign.partial_estimated_min_price &&

                        <PricingComponent
                            partial_estimated_min_price={campaign.partial_estimated_min_price}
                            full_estimated_min_price={campaign.full_estimated_min_price}
                        />

                    }


                    <DonorSummary donorSummary={donorSummary} />
                    <Row >
                        <Col className="my-3" xs={12}>

                            <Button onClick={() => handleUpdateShareModal(true)} variant="primary" className="rounded-pill p-2 fs-5 w-100"><strong>Share</strong></Button>

                        </Col>
                        {
                            campaign.status === 'active' &&
                            <>
                                <Col xs={12}>
                                    <Link to={{ pathname: '/campaign-payment/' + campaign.url }}
                                        // <Link to={{ pathname: '/campaign-payment/' + campaign.id }}
                                        state={{ campaign: campaign }}>
                                        <Button variant="success" className="rounded-pill p-2 fs-5 w-100"><strong>Contribute</strong></Button>
                                    </Link>
                                </Col>
                                <Col className="d-flex mt-3 justify-content-center" xs={12}>
                                    <div className="mx-3"><Button onClick={() => handleShowDonationProtectedModal(true)} className="rounded-pill py-0 px-4" variant="dark" style={{ border: 'white 1px solid' }} ><strong>Contribution info</strong></Button></div>


                                    <div className="mx-3"><Button onClick={() => handleWhatCanBePostedModal(true)} className="rounded-pill py-0 px-4" variant="dark" style={{ border: 'white 1px solid' }} ><strong>What should I contriubte too?</strong></Button></div>
                                </Col>
                            </>
                        }
                    </Row>
                </Card.Body>
            </Card>

        </Col >




        </>
    )
}

export default CampaignPageDesktopStack
