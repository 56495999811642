import React, { useState, useEffect, useRef } from 'react'
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { useApi } from '../../contexts/ApiProvider';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { useParams } from 'react-router'
import FlashMessage from "../../components/FlashMessage"
import { useFlash } from '../../contexts/FlashProvider';
import { useNavigate } from 'react-router-dom';
import NewCampaignBreadcrumb from '../../components/NewCampaignBreadcrumb';
import { addBillboardCluster, handleShowBillboardLayer } from '../../utils/ShowBillboardLayers'
import NewCampaignTypesMobile from './NewCampaignTypesMobile';
import NewCampaignTypesDesktop from './NewCampaignTypesDesktop';
import { useUser } from '../../contexts/UserProvider';


function NewCampaignTypes() {

    // const { darkTheme } = useTheme()
    const { createCampaign } = useUser()
    const boundaries = useRef(null)
    const [modes, setModes] = useState();
    const [disabled, setDisabled] = useState(true);
    const api = useApi();
    const [selectedCardId, setSelectedCardId] = useState(null);
    // const [showMap, setShowMap] = useState(true)
    const { campaignId } = useParams()
    const mapRefs = {
        'mobile': { map: useRef(null), container: useRef(null) },
        'desktop': { map: useRef(null), container: useRef(null) },

    }
    const flash = useFlash();
    const [show, setShow] = useState(false);
    const navigate = useNavigate();


    const SUB_TITLE = 'The points on the map represent billboard spots while the red boundaries represent mobile truck campaign areas. Toggle the switches below to show or hide each boundary.'
    const TITLE = 'Select a campaign type'
    // const toggleMap = () => showMap ? setShowMap(false) : setShowMap(true)

    const handleSubmit = (e, proceed) => {
        proceed ?
            (async () => {

                const response = await api.post(`/new_campaign/campaign_type`, JSON.stringify({ mode: modes[selectedCardId].name, proceed: true }));
                if (response.ok) {

                    setDisabled(false)
                    return navigate(`/new_campaign/location`, { replace: true });

                }
                else {

                    flash(response.body.errors.json.message)
                    setDisabled(false)
                }
            })()
            :
            (async () => {
                const response = await api.post(`/new_campaign/campaign_type`, JSON.stringify({ mode: modes[selectedCardId].name }));
                if (response.ok) {
                    if (('existing' in response.body) && (response.body.existing === true)) {

                        setShow(true)


                    }
                    else {
                        setDisabled(false)
                        return navigate(`/new_campaign/location`, { replace: true });
                    }
                }
                else {

                    flash(response.body.errors.json.message)
                    setDisabled(false)
                }
            })()

    }


    useEffect(() => {


        (async () => {
            const response = await api.get(`/new_campaign/campaign_type`);

            if (response.ok) {

                setModes(response.body.modes)
                console.log(response.body.modes)
                if ("mode_id" in response.body) {
                    setSelectedCardId(response.body.mode_id - 1);
                    setDisabled(false)
                }
            }
            else {


                if (response.status === 429) {
                    console.log('429 response')
                    createCampaign();
                    // (async () => {
                    //     const response2 = await api.post(`/new_campaign/create-campaign`,);

                    //     if (response2.ok) {

                    //         setModes(response2.body.modes)
                    //         console.log(response2.body.modes)
                    //         if ("mode_id" in response2.body) {
                    //             setSelectedCardId(response2.body.mode_id - 1);
                    //             setDisabled(false)
                    //         }
                    //     }
                    //     else {

                    //         flash(response2.body.errors.json.message, 'danger')
                    //         return
                    //     }


                    // })();
                }
                else {
                    flash(response.body.errors.json.message, 'danger')
                }
            }


        })();

    }, [api, flash])


    const handleCardClick = (e, id) => {
        setSelectedCardId(id);
        setDisabled(false);
        // if (!e.target.className.includes('btn')) {
        // }
    };

    useEffect(() => {
        const BILLBOARD_POINTS_LAYER = 'billboards_1';

        Object.keys(mapRefs).forEach((fieldRef) => {

            let map = mapRefs[fieldRef]['map']
            if (!map.current) {


                map.current = new mapboxgl.Map({
                    container: mapRefs[fieldRef]['container'].current,
                    style: process.env.REACT_APP_MAPBOX_NEW_CAMPAIGN_TYPES_V1,
                    center: [-103.8521, 44.6714],
                    zoom: 4,
                    projection: 'mercator',
                    minZoom: 4,

                });

                map.current.on('load', () => {

                    map.current.addControl(new mapboxgl.FullscreenControl(), 'top-left');


                    boundaries.current = map.current.queryRenderedFeatures({
                        layers: ['truck-area'], // Replace with your actual layer IDs,
                        // layers: ['billboard-area', 'truck-area'], // Replace with your actual layer IDs,
                        filter: ['!=', '$type', 'Point']
                    });


                    var resp = addBillboardCluster(map)
                    if (!resp) {
                        return
                    }

                    // function isPointInPolygon(point, polygon) {
                    //     const [x, y] = point;
                    //     let inside = false;
                    //     for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
                    //         const [xi, yi] = polygon[i];
                    //         const [xj, yj] = polygon[j];
                    //         const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
                    //         if (intersect) inside = !inside;
                    //     }
                    //     return inside;
                    // }


                });
            }


        });

        return () => {
            // mapDesktop.remove();
            // mapMobile.remove();
        }// Cleanup on unmount

    }, []); //radius was here
    // }, [mapRefs]); //radius was here





    const updateItem = (index, newValue) => {
        setModes(prevItems =>
            prevItems.map((item, i) =>
                i === index ? { ...item, ...newValue } : item
            )
        );
        console.log(modes)
    };



    const handleHideLayer = (mode_id) => {

        if (!modes[mode_id].state) {
            updateItem(mode_id, { state: true });
            if (modes[mode_id].name.includes('truck')) {
                Object.keys(mapRefs).forEach((fieldRef) => {
                    mapRefs[fieldRef]['map'].current.setLayoutProperty('truck-area', 'visibility', 'visible');

                })
            }
            else if (modes[mode_id].name.includes('Billboard')) {
                Object.keys(mapRefs).forEach((fieldRef) => {
                    let map = mapRefs[fieldRef]['map']
                    handleShowBillboardLayer(map, true)
                })
                // map.current.setLayoutProperty('billboard-points', 'visibility', 'visible');
                // map.current.setLayoutProperty('billboard-area', 'visibility', 'visible');
            }

        }
        else {
            updateItem(mode_id, { state: false });
            if (modes[mode_id].name.includes('truck')) {
                Object.keys(mapRefs).forEach((fieldRef) => {
                    let map = mapRefs[fieldRef]['map'];
                    map.current.setLayoutProperty('truck-area', 'visibility', 'none');

                })
            }
            else if (modes[mode_id].name.includes('Billboard')) {
                Object.keys(mapRefs).forEach((fieldRef) => {
                    let map = mapRefs[fieldRef]['map'];

                    handleShowBillboardLayer(map, false)
                })


                // map.current.setLayoutProperty('billboard-points', 'visibility', 'none');
                // map.current.setLayoutProperty('billboard-area', 'visibility', 'none');
            }
        }


    }




    return (



        <Container fluid style={{
            height: "100%",
            overflow: 'hidden',
            margin: 0,

        }}
        className="p-sm-0  p-lg-2"
        >
            <FlashMessage />
            <Modal centered show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton >
                    <Modal.Title className="fw-bold">
                        Before continuing...
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    Changing your campaign's mode will remove your exisiting campaign images and location. Click "continue" to proceed.
                </Modal.Body>
                <Modal.Footer >
                    <Button onClick={(e) => handleSubmit(e, 'proceed')}>
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="mx-lg-2 d-lg-block">

                {<NewCampaignBreadcrumb currentStep={2} campaignId={campaignId} />}

            </div>


            <NewCampaignTypesDesktop map={mapRefs['desktop']} selectedCardId={selectedCardId} handleCardClick={handleCardClick} title={TITLE} subTitle={SUB_TITLE} modes={modes} disabled={disabled} handleHideLayer={handleHideLayer} handleSubmit={handleSubmit} />


            <NewCampaignTypesMobile map={mapRefs['mobile']} selectedCardId={selectedCardId} handleCardClick={handleCardClick} title={TITLE} subTitle={SUB_TITLE} handleHideLayer={handleHideLayer} disabled={disabled} modes={modes} handleSubmit={handleSubmit} />
        </Container>




    )
}

export default NewCampaignTypes
