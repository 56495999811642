import { HiUserGroup } from "react-icons/hi"
import { MdOutlineTheaterComedy } from "react-icons/md";
import { GiPaintBrush } from "react-icons/gi";
import { PiShootingStar } from "react-icons/pi";
import { FcNews } from "react-icons/fc";
import { GiPoliceBadge } from "react-icons/gi";


export const whoItForIcons = [
    {
        icon: <HiUserGroup style={{ color: 'whitesmoke', background: "linear-gradient(45deg, rgb(115, 115, 115), rgb(28, 96, 4), rgb(26, 162, 156))", border: '1px solid white', borderRadius: '.2rem', padding: '.2rem', backgroundColor: 'white' }} size={50} />,
        name: "Everyone!",
        description: "CrowdPost was created to let people have a say in their public discourse.",
    },
    {
        icon: <FcNews size={50} />,
        name: "Journalists & authors",
        description: "Bring your reporting directly to your audience",
    },
    {
        icon: <MdOutlineTheaterComedy style={{ color: 'whitesmoke', background: "linear-gradient(45deg, rgb(115, 115, 115), rgb(6, 111, 47), rgb(74, 196, 196))", border: '1px solid white', borderRadius: '.2rem', padding: '.2rem', }} size={50} />,
        name: "Comedians",
        description: "Pick a clever area and get your material out there",
    },
    {
        icon: <GiPaintBrush style={{ color: 'whitesmoke', border: '1px solid white', borderRadius: '.2rem', padding: '.2rem', background: 'linear-gradient(45deg, rgb(115, 115, 115), rgb(111, 6, 104), rgb(196, 183, 74))' }} size={50} />,
        name: "Artists",
        description: "Showcase your art where it matters most",
    },
    {
        icon: <PiShootingStar style={{ background: "linear-gradient(45deg, rgb(115, 115, 115), rgb(255, 214, 136), rgb(162, 109, 26))", border: '1px solid white', padding: '.2rem', color: 'whitesmoke' }} size={50} />,
        name: "Social influencers",
        description: "Launch your voice outside the online echo chambers and into key points of interest",
    },
    {
        icon: <GiPoliceBadge style={{ background: "linear-gradient(45deg, rgb(9, 24, 255), rgb(136, 194, 255), rgb(0, 15, 247))", border: '1px solid white', borderRadius: '.2rem', padding: '.2rem', color: 'whitesmoke' }} size={50} />,
        name: "Cold Case & Missing People",
        description: "Create no-cost campaigns to raise awarness in your community",
    }
]