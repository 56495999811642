import React, { useState, useEffect, forwardRef } from 'react'
import BaseMessageImage from './BaseMessageImage';

import Draggable from './Draggable';
import { useImageBuilder } from '../contexts/ImageBuilderProvider';

const MediaSimulation = forwardRef(({ index, imageHover, handleImageHover, minFontSize, activeTab, mediaName, image_data, height, messageWidth, value, handleImagePositionChange, componentTab }, ref) => {


    const { disabled, respData } = useImageBuilder();
    const [isCentered, setIsCentered] = useState(false);

    const [verticallyCentered, setVerticallyCentered] = useState(false);
    const [horizontallyCentered, setHorizontallyCentered] = useState(false);


    const [isDragging, setIsDragging] = useState(false);

    const handleIsDraggingChange = (data) => setIsDragging(data)

    const handleMouseOut = () => {
        handleImageHover(false);

    }

    const handleMouseEnter = () => {
        handleImageHover(true);

    }




    // const handleResizeMouseOver = (e) => {
    //     e.preventDefault();
    //     const textareaRect = e.target.getBoundingClientRect();
    //     const mouseX = e.clientX - textareaRect.left;
    //     const mouseY = e.clientY - textareaRect.top;
    //     const resizeIconX = textareaRect.width - 10; // 10px is the width of the resize icon
    //     const resizeIconY = textareaRect.height - 10; // 10px is the height of the resize icon

    //     // if (mouseX >= resizeIconX && mouseY >= resizeIconY) {
    //     //     console.log('Mouse is over the resize icon!');

    //     // }

    // };

    useEffect(() => {
        const handleClickInside = (event) => {
            if (ref.current && ref.current.contains(event.target)) {

                handleImageHover(true);

            }
        };

        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {

                imageHover && handleImageHover(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        document.addEventListener('touch', handleClickOutside);
        if (ref.current) {
            ref.current.addEventListener('click', handleClickInside);
            ref.current.addEventListener('touch', handleClickInside);
        }

        // Clean up the event listeners on component unmount
        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('touch', handleClickOutside);
            if (ref.current) {
                ref.current.removeEventListener('click', handleClickInside);
                ref.current.removeEventListener('touch', handleClickInside);
            }
        };
    }, [ref]);





    const handleIsCentered = (isCenteredHorizontally, isCenteredVertically) => {


        setVerticallyCentered(isCenteredHorizontally)
        setHorizontallyCentered(isCenteredVertically)
        // if (isCenteredHorizontally || isCenteredVertically) {

        //     console.log(isCenteredHorizontally && isCenteredVertically, 'Centered')
        // }
        setIsCentered(isCenteredHorizontally && isCenteredVertically)
    };


    const simulationStyle = image_data['image']['is_gradient'] === true ?
        {
            aspectRatio: `${image_data['aspectRatio']}`,
            maxHeight: '400px',
            position: 'sticky',

            background: `linear-gradient(${image_data['image']['gradient_deg']}deg, ${image_data['image']['gradient_color_1']}, ${image_data['image']['gradient_color_2']})`,
            // position: 'relative',
            overflow: 'hidden',
            // backgroundImage: (activeTab === 'dragDrop' || activeTab == 'aiImage' || (activeTab === 'splitPost' && image_data['image']['fullscreen']) || (activeTab === 'externalUpload')) && image_data['image']['url'] && `url(${image_data['image']['url']})`,
            // backgroundPosition: image_data['image']['background_position'],
            // backgroundSize: image_data['image']['background_size'],
            // backgroundRepeat: image_data['image']['background_repeat'],
            padding: disabled ? `${(height * .04) + 2}px` : `${height * .04}px`,
            fontSize: minFontSize + 'px',
            border: disabled ? "none" : "2px solid black"
        }
        :
        {
            aspectRatio: `${image_data['aspectRatio']}`,
            maxHeight: '400px',
            position: 'sticky',
            backgroundColor: !image_data['image']['background_color'] ? 'white' : image_data['image']['background_color'],
            // position: 'relative',
            overflow: 'hidden',
            // backgroundImage: (activeTab === 'dragDrop' || activeTab == 'aiImage' || (activeTab === 'splitPost' && image_data['image']['fullscreen']) || (activeTab === 'externalUpload')) && image_data['image']['url'] && `url(${image_data['image']['url']})`,
            // backgroundPosition: image_data['image']['background_position'],
            // backgroundSize: image_data['image']['background_size'],
            // backgroundRepeat: image_data['image']['background_repeat'],
            padding: disabled ? `${(height * .04) + 2}px` : `${height * .04}px`,
            fontSize: minFontSize + 'px',
            border: disabled ? "none" : "2px solid black"
        }


    return (

        <div
            ref={activeTab === componentTab ? ref : null}
            id={mediaName}
            style={simulationStyle}
            // onMouseMove={handleMouseMove}
            // onMouseUp={handleMouseUp}
            onMouseOver={handleMouseEnter}
            onMouseOut={handleMouseOut}
            onClick={handleMouseEnter}

        >

            {image_data['image']['url'] && image_data['image']['url'].length > 2 &&

                <img src={`${image_data['image']['url']}`} style={{
                    // objectPosition: image_data['image']['background_position'],
                    top: '0',
                    left: '0',
                    width: '100%',
                    position: 'absolute',
                    height: '100%',
                    transform: `translate(${image_data['image']['background_img_x']}%, ${image_data['image']['background_img_y']}%) scale(${image_data['image']['background_img_scale'] ? image_data['image']['background_img_scale'] : 1 })`, //TODO: Make x and y columns in DB to control this. and give pre set positions
            // backgroundPosition: image_data['image']['background_position'],
            objectFit: image_data['image']['background_size'],
                }}
                />
            }

            <div style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: `rgba(0, 0, 0, ${image_data['image']['background_opacity_filter']})`, //TODO:MAKE CONTROL THIS AS darken background feature
            }}></div>

            {isDragging && horizontallyCentered && <div className="center-line horizontal"></div>}
            {isDragging && verticallyCentered && <div className="center-line vertical"></div>}

            {
                activeTab === 'dragDrop' &&



                // 'image_messages' in image_data && image_data['image_messages'].map((v, _i) =>
                'image_messages' in respData['image_types'][mediaName] && respData['image_types'][mediaName]['image_messages'].map((v, _i) =>
                    (v['message'] || v['image_url']) &&


                    <Draggable
                        handleIsCentered={handleIsCentered}

                        mediaRef={ref} //used to center content with parent
                        mediaName={mediaName}
                        dragging={isDragging}
                        handleIsDraggingChange={handleIsDraggingChange}
                        scale={{ x: v['x_scale'], y: ['y_scale'] }}
                        handleImageHover={handleImageHover}
                        angle={v['angle']}
                        handleImagePositionChange={handleImagePositionChange}
                        index={_i}
                        // initialPos={
                        //     {
                        //         x: v['x_position'],
                        //         y: v['y_position']
                        //     }
                        // }
                        // initialSize={
                        //     {
                        //         width: v['width'],
                        //         height: v['height']
                        //     }
                        // }
                        key={_i}

                        imageHover={imageHover}
                        zIndex={v['z_index']}
                    >


                        <div

                            // onChange={handleMessageChange}

                            // onMouseEnter={handleMouseEnter}
                            style={{
                                // resize: imageHover ? 'both' : 'none',
                                // resize: imageHover ? 'both' : 'none',
                                transform: `scaleX(${v['x_scale']}) scaleY(${v['y_scale']})`,
                                alignContent: v['vertical_align'],
                                // WebkitAlignContent: v['vertical_align'],
                                // alignItems: v['vertical_align'],
                                // display:'-webkit-flex',
                                overflow: "hidden",
                                whiteSpace: 'pre-wrap',
                                cursor: isDragging ? 'grabbing' : 'grab',
                                // marginBottom: '10px',
                                // minWidth: 'fit-content',
                                width: '100%',
                                height: '100%',

                                //   backgroundColor: 'green',
                                // height: `${topMessageDimensions['y']}px`,
                                // width: `${topMessageDimensions['x']}px`,
                                // height: `${topMessageDimensions['y']}px`,
                                // background: 'transparent',
                                backgroundImage: v['image_url'] && `url("${v['image_url']}")`,
                                backgroundPosition: v['image_position'],
                                backgroundSize: v['image_size'],
                                backgroundRepeat: v['image_repeat'],
                                color: v['font_color'],
                                border: ((disabled == true)) ? 'none' : ((imageHover == true) || (isDragging == true)) ? '2px dashed grey' : 'none',
                                display: (v['image_url'] || v['message']) ? 'block' : 'none',
                                outline: 'none',
                                fontSize: `${v['font_size']}%`,
                                fontFamily: `${v['font_family']}`,
                                fontWeight: v['bold'] ? 'bold' : 'normal',
                                fontStyle: v['italic'] ? 'italic' : 'normal',
                                textDecoration: v['underline'] ? 'underline' : 'none',
                                backgroundColor: v['is_gradient'] === false ? v['background_color'] : 'transparent',
                                padding: '.1em',
                                textAlign: v['text_align'],
                                lineHeight: .9,
                                userSelect: 'none',

                                textShadow: `
                                    ${v['outline_width']}em 0px ${v['outline_blur']}em ${v['outline_color']}, 
                                    0px ${v['outline_width']}em ${v['outline_blur']}em ${v['outline_color']}, 
                                    -${v['outline_width']}em 0px ${v['outline_blur']}em ${v['outline_color']}, 
                                    0px -${v['outline_width']}em ${v['outline_blur']}em ${v['outline_color']}, 
                                    -${v['outline_width']}em -${v['outline_width']}em ${v['outline_blur']}em ${v['outline_color']}, 
                                    -${v['outline_width']}em ${v['outline_width']}em ${v['outline_blur']}em ${v['outline_color']}, 
                                    ${v['outline_width']}em -${v['outline_width']}em ${v['outline_blur']}em ${v['outline_color']}, 
                                    ${v['outline_width']}em ${v['outline_width']}em ${v['outline_blur']}em ${v['outline_color']}
                              `

                            }}
                        // id="topMessage"
                        // onMouseDown={handleMouseDown}
                        // onDragStart={(e) => e.preventDefault()}
                        >
                            {/* {v['message'] ? v['message'] : `${image_data['image']['url']}`}
                            {v['message'] ? v['message'] : `${image_data['image']['url']}`} */}

                            <div style={{
                                textAlign: v['text_align'],
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: v['text_align'],
                                alignContent: v['vertical_align'], // This will work in most browsers (e.g., Firefox, Chrome)
                                WebkitAlignContent: v['vertical_align'], // Safari prefix for compatibility
                                height: '100%', // Ensuring the height is set
                                // padding: '.1rem',
                            }}>{!v['message'] ? '' : v['message']}</div>

                        </div>

                    </Draggable>



                )


            }


            <div style={{
                // border: disabled ? 'none' : `.5px dashed red`,
                boxShadow: disabled ? 'none' : 'inset 0 0 0 1.5px grey',
                width: '100%',
                height: height - (height * .08),
                boxSizing: 'border-box',
                position: 'relative',


            }}>
                {
                    activeTab === 'splitPost' && parseFloat(image_data['aspectRatio']) <= 1.5 ?
                        <BaseMessageImage image_data={image_data} mediaText={image_data['base_message']['message']} topHalf={true} height={height} value={value} minFontSize={minFontSize} />
                        :
                        activeTab === 'splitPost' && parseFloat(image_data['aspectRatio']) >= 1.5 &&
                        <BaseMessageImage image_data={image_data} mediaText={image_data['base_message']['message']} topHalf={false} height={height} value={value} minFontSize={minFontSize} />
                }
            </div >
        </div >



    )
});

export default MediaSimulation
